import { Space, TimePicker } from "antd";
import axios from "axios";
import moment from "moment";
import Notiflix from "notiflix";
import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  FloatingLabel,
  Form,
  Modal,
  Row
} from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import Offcanvas from "react-bootstrap/Offcanvas";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import {
  BiFoodMenu,
  BiLockAlt,
  BiMailSend,
  BiSolidCalendar
} from "react-icons/bi";
import applycoupongif from "../../assets/img/applycoupon.gif";
import thankyouImg from "../../assets/img/thankyou.png";

import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import {
  FaAddressBook, FaCalendarAlt,
  FaCheck,
  FaMailchimp,
  FaMinus,
  FaMobileAlt,
  FaPepperHot,
  FaPlus,
  FaRegCommentDots,
  FaShippingFast,
  FaShoppingCart,
  FaTicketAlt
} from "react-icons/fa";
import { IoArrowUndo } from "react-icons/io5";
import { MdLocationOn } from "react-icons/md";
import { VscCreditCard, VscGraph } from "react-icons/vsc";
import "react-multi-carousel/lib/styles.css";
import { Link, useNavigate } from "react-router-dom";
// import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import { Swiper, SwiperSlide } from "swiper/react";
import NonVegetarian from "../../assets/img/nonveg_icon.png";
import Vegan from "../../assets/img/vegan_icon.png";
import Vegetarian from "../../assets/img/veg_icon.png";
import GetApiCall from "../../helper/GetApi";
import PostApiCall from "../../helper/PostAPI";
import { store } from "../../pages/context/store";
import useGeoLocation from "../../pages/CustomHooks/useGeoLocation";
import CopyConfig from "../Configuration/CopyConfig";
import "./QuickSelection.css";

import { AES } from "crypto-js";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { FreeMode, Pagination } from "swiper/modules";

// For Map
import { Tabs } from "antd";
import { GiChickenOven } from "react-icons/gi";
import Map from "./Map";
function QuickSelection() {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [name, setName] = useState("");
  const { cartTotal, setCartTotal } = useContext(store);
  const { currencyCode, setCurrencyCode } = useContext(store);
  const { cartItems, setCartItems } = useContext(store);
  const { showLoginModal, setShowLoginModal } = useContext(store);
  const {
    cuisines,
    setcuisines,
    allcuisines,
    setallcuisines,
    menuListItems,
    setScrollTodiv,
    activeTab,
    setActiveTab,
    setSearchText,
    restaurantOpen,
    appliedCouponId,
    setAppliedCouponId
  } = useContext(store);

  const [contact, setContact] = useState([]);
  const [emailAddress, setEmailAddress] = useState("");
  const handleClose = () => {
    setShow(false);
    setToggleOtp(false);
  };
  const [orderingMethods, setOrderingMethods] = useState([]);
  const [minusDisabled, setMinusDisabled] = useState(false);
  const { customerData, setCustomerData } = useContext(store);
  const [scroll, setScroll] = useState(false);

  const [deliveryType, setDeliveryType] = useState(sessionStorage.getItem("OrderType") ? sessionStorage.getItem("OrderType") : "TakeAway");
  const [deliveryAddress, setDeliveryAddress] = useState(null);
  const [scheduleOrder, setScheduleOrder] = useState("Now");
  const [scheduleOrderDate, setScheduleOrderDate] = useState(null);
  const [showCalendar, setShowCalendar] = useState(false);
  const [scheduleOrderTime, setScheduleOrderTime] = useState(null);
  const [deliveryInstructions, setDeliveryInstructions] = useState(null);
  const [additionalInstructions, setadditionalInstructions] = useState(null);
  const { keyStrings, setKeyStrings, showAddressModal, setShowAddressModal } =
    useContext(store);
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowAddressModal(false);
  const handleShowModal = () => setShowAddressModal(true);
  const { cartData, setCartData } = useContext(store);
  const [offerList, setOfferList] = useState([]);
  const [defaultActiveKey, setDefaultActiveKey] = useState("3")
  const [selectedDate, setSelectedDate] = useState("");
  const [spiceLevel, setSpiceLevel] = useState(null);
  const [calendarDateRange, setCalendarDateRange] = useState({
    minDate: new Date(),
    maxDate: new Date(),
  });
  useEffect(() => {
    if (window.pageYOffset > 300) {
      setScroll("scroll");
    } else {
      window.addEventListener("scroll", () => {
        setScroll(window.scrollY > 300);
      });
    }
  }, []);
  const windowScrollY = () => {
    window.scroll({
      top: window.innerWidth > 967 ? 350 : 500, // Calculate the absolute position of the filtered items
      behavior: "smooth",
    });
  };
  const [customerAddress, setCustomerAddress] = useState([]);
  // discount
  const [discountAmount, setdiscountAmount] = useState(null);
  // total
  const [totalAmount, settotalAmount] = useState(0.0);

  // modal for config selection
  const [configModal, setConfigModal] = useState(false);

  const { itemId, setItemId } = useContext(store);
  const { selectedCartLineId, setSelectedCartLineId } = useContext(store);

  // address
  const { mapAddress, setMapAddress } = useContext(store);
  const { updateCustomerAddressData, setupdateCustomerAddressData } =
    useContext(store);
  const { mapPostitionMarkerLat, setmapPostitionMarkerLat } = useContext(store);
  const { mapPostitionMarkerLang, setmapPostitionMarkerLang } =
    useContext(store);
  const { loginDisabled, setLoginDisabled, clientLogo } = useContext(store);
  const { noDeliveryZone, setNoDeliveryZone } = useContext(store);
  const [center, setCenter] = useState({ lat: 13.084622, lng: 80.248357 });
  const [lat, setLat] = useState(null);
  const [lang, setLang] = useState(null);
  const [addressData, setAddressData] = useState(null);
  const [mobileNumber, setMobileNumber] = useState(null);
  // const [mobileNumber, setMobileNumber] = useState("");
  const [confirmAddress, setConfirmAddress] = useState(null);
  const [contactName, setContactName] = useState(null);
  const [floor, setFloor] = useState(null);
  const [landmark, setLandmark] = useState(null);
  const [pincode, setPincode] = useState(null);
  const [title, setTitle] = useState("Home");
  const [addressId, setAddressId] = React.useState(null);
  const { quantityAdd, setQuantityAdd } = useContext(store);
  const { currentItemConfig, setCurrentItemConfig } = useContext(store);
  const { setSelectedAddons, setSelectedProperties } = useContext(store);
  const [otp, setOtp] = useState("");
  const { showCopyConfig, setShowCopyConfig } = useContext(store);
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [toggleOtp, setToggleOtp] = useState(false);
  const [tip, setTip] = useState(null);
  const [tipType, setTipType] = useState("");

  const [visible, setVisible] = useState({
    start: 6,
    end: 12,
    clicked: "more",
  });

  // payment modal
  const [paymentModal, setPaymentModal] = React.useState(false);
  const [paymentSuccesModal, setpaymentSuccesModal] = React.useState(false);
  const [paymentEmail, setpaymentEmail] = React.useState("");
  const [paymentCVC, setPaymentCVC] = React.useState("");
  const [paymentCardNumber, setpaymentCardNumber] = useState("");
  const [paymentExpiry, setpaymentExpiry] = useState("");
  const [enteredCouponCode, setEnteredCouponCode] = useState("");
  const [couponErrorCodeTextBox, setCouponErrorCodeTextBox] = useState("");
  const [couponAppliedPopup, setCouponAppliedPopup] = useState(false);
  const [numRegex, setnumRegex] = React.useState(/^[0-9]*$/);
  const [emailRegex, setEmailRegex] = useState(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  const handlepaymentModalClose = () => {
    setPaymentModal(false);
  };
  const handleClosepaymentSuccesModal = () => {
    setpaymentSuccesModal(false);
  };
  const onChangeCardNumber = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setpaymentCardNumber(e.target.value);
    }
  };
  const onChangeExp = (e) => {
    const re = /^[0-9]/;

    if (e.target.value === "" || re.test(e.target.value)) {
      setpaymentExpiry(e.target.value);
    }
  };
  const expriy_format = (value) => {
    const expdate = value;
    if (expdate != null) {
      const expDateFormatter =
        expdate.replace(/\//g, "").substring(0, 2) +
        (expdate.length > 2 ? "/" : "") +
        expdate.replace(/\//g, "").substring(2, 6);

      return expDateFormatter;
    }
  };
  function onPaymentKeyBank() {
    if (paymentEmail !== "") {
      if (emailRegex.test(paymentEmail)) {
        if (paymentCardNumber !== "") {
          if (paymentExpiry !== "") {
            if (paymentCVC !== "") {
              Notiflix.Loading.Circle();
              var login = localStorage.getItem("LoginDetailsWeb");
              var logindetails = JSON.parse(login);
              var Order = localStorage.getItem("OrderData");
              var Orderdetails = JSON.parse(Order);
              const id = Orderdetails.order_id;
              const secretKey = "mel#4321!";
              const encryptedID = AES.encrypt(
                JSON.stringify(id.toString()),
                secretKey
              ).toString();
              const urlSafeorderid = encryptedID
                .replace(/\//g, "-")
                .toString()
                .replace("?", "bechofy");
              const cardExpiry = AES.encrypt(
                JSON.stringify(
                  paymentExpiry.replace(/[^a-zA-Z0-9 ]/g, "").toString()
                ),
                secretKey
              ).toString();
              const urlSafeexpiry = cardExpiry
                .replace(/\//g, "-")
                .toString()
                .replace("?", "bechofy");
              const paymentCVV = AES.encrypt(
                JSON.stringify(paymentCVC.toString()),
                secretKey
              ).toString();
              const urlSafePaymentCvv = paymentCVV
                .replace(/\//g, "-")
                .toString()
                .replace("?", "bechofy");
              const cardNumber = AES.encrypt(
                JSON.stringify(paymentCardNumber.toString()),
                secretKey
              ).toString();
              const urlSafeCardNumber = cardNumber
                .replace(/\//g, "-")
                .toString()
                .replace("?", "bechofy");
              PostApiCall.postRequest(
                {
                  accountNumber: urlSafeCardNumber,
                  expiry: urlSafeexpiry,
                  cvv: urlSafePaymentCvv,
                  amount: parseFloat(totalAmount).toFixed(2),
                  orderid: urlSafeorderid,
                  name: logindetails[0].fld_name,
                },
                "KeyBankPayment"
              ).then((result) => {
                result.json().then((obj) => {
                  if (result.status == 200 || result.status == 201) {
                    if (obj.resptext == "Approval") {
                      updatePaymentStatus(obj.retref);
                      // updateCustomerProfile();
                    } else {
                      Notiflix.Notify.Failure(obj.resptext);
                    }
                  }
                });
              });
            } else {
              Notiflix.Notify.Failure("Please enter CVV");
            }
          } else {
            Notiflix.Notify.Failure("Please enter expiry date");
          }
        } else {
          Notiflix.Notify.Failure("Please enter card number");
        }
      } else {
        Notiflix.Notify.Failure("Please enter valid email");
      }
    } else {
      Notiflix.Notify.Failure("Please enter email");
    }
  }

  function updatePaymentStatus(trxnnum) {
    PostApiCall.postRequest(
      {
        orderid: JSON.parse(localStorage.getItem("OrderData")).order_id,
        status: "succeeded",
        paymenttrxid: trxnnum,
        time: moment().format("MMMM Do YYYY, h:mm:ss a"),
      },
      "UpdatePaymentStatus"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          updateOrderStatus();
        }
      })
    );
  }
  function updateOrderStatus() {
    var login = localStorage.getItem("LoginDetailsWeb");
    var logindetails = JSON.parse(login);
    Notiflix.Loading.Circle();
    PostApiCall.postRequest(
      {
        id: JSON.parse(localStorage.getItem("OrderData")).order_id,
        status: "NEW",
      },
      "UpdateOrderStatus"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          SendAndroidPush(obj.message[0]);
          PostApiCall.postRequest(
            {
              customerName: obj.message[0].customername,
              orderValue: parseFloat(obj.message[0].orderamount).toFixed(2),
              phoneNumber: "+12063696165",
            },
            "smsSendToAdmin"
          ).then((res) => {
            if (res.status == 200 || res.status == 201) {
              // window.location.href = "/order-online";
              // localStorage.removeItem("OrderData");
            } else {
              //console.log("error")
            }
          });
          Notiflix.Loading.Remove();
        }
      })
    );
  }
  const updateCustomerProfile = () => {
    PostApiCall.postRequest(
      {
        email: emailAddress,
      },
      "updateCustomerProfile"
    ).then((result) =>
      result?.json().then((obj) => {
        if (result.status == 200 || result.status == 201) {
        }
      })
    );
  };
  // date releated data
  const today = new Date();
  const dateOptions = []; // storing coming 7 days

  for (let i = 0; i < 7; i++) {
    const date = new Date(today);
    date.setDate(today.getDate() + i);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    const formattedDate = `${day}-${month}-${year}`;
    dateOptions.push(formattedDate);
  }

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  //address end

  const location = useGeoLocation();
  useEffect(() => {
    if (window.pageYOffset > 300) {
      setScroll("scroll");
    } else {
      window.addEventListener("scroll", () => {
        setScroll(window.scrollY > 300);
      });
    }
  }, []);
  useEffect(() => {
    getCart();
    if (loginDisabled == false) {
      getAddress();
    }
    GetApiCall.getRequest("getOrderingMaster").then((results) =>
      results?.json().then((obj1) => {
        if (results.status == 200 || results.status == 201) {
          if (obj1.message != null || obj1.message != undefined) {
            setOrderingMethods(obj1.message);
          }
          Notiflix.Loading.Remove();
        }
      })
    );
  }, []);

  // TimePicker
  const onChange = (time, timeString) => {
    setScheduleOrderTime(timeString);
  };

  const [showMenu, setShowMenu] = useState(false);

  const handleCloseMenu = () => setShowMenu(false);
  const handleShowMenu = () => setShowMenu(true);

  // get distance for delivery
  function showMyLocation() {
    if (location.loaded && !location.error) {
      var moilat = 47.70923430939719;
      var moilng = -122.32561495683619;

      setLat(location.coordinates.lat);
      setLang(location.coordinates.lng);
      axios
        .get(
          "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
          location.coordinates.lat +
          "," +
          location.coordinates.lng +
          "&key=AIzaSyA4UpIpBjkSzH8T280zKffdTVdnt170Ke4"
        )
        .then((res) => {
          setmapPostitionMarkerLat(res.data.results[0].geometry.location.lat);
          setmapPostitionMarkerLang(res.data.results[0].geometry.location.lng);
          setMapAddress(res.data.results[0].formatted_address);
          PostApiCall.postRequest(
            {
              originLat: moilat,
              originLng: moilng,
              destinationLat: res.data.results[0].geometry.location.lat,
              destinationLng: res.data.results[0].geometry.location.lng,
            },
            "GoogleDistanceMatrix"
          ).then((results) => {
            results.json().then((obj) => {
              if (results.status == 200 || results.status == 201) {
                // addAddress();
              } else {
                setAddressData(null);
                setNoDeliveryZone(true);
              }
            });
          });
        });
    } else {
      alert(location.error.message);
    }
  }

  const getCart = async () => {
    var login = localStorage.getItem("LoginDetailsWeb");
    var logindetails = login ? JSON.parse(login)[0] : null;

    var Order = localStorage.getItem("OrderData");
    var Orderdetails = JSON.parse(Order);

    PostApiCall.postRequest(
      {
        orderid:
          localStorage.getItem("OrderData") == null
            ? null
            : JSON.parse(localStorage.getItem("OrderData")).order_id,
        id:
          localStorage.getItem("LoginDetailsWeb") == null
            ? null
            : logindetails?.CustomerId,
        stateid: null,
        ordertype: sessionStorage.getItem("OrderType")
      },
      "getCartWebsite"
    ).then((result) =>
      result?.json().then((obj) => {
        if (result.status == 200 || result.status == 201) {
          if (obj.message.length == 0) {
            localStorage.removeItem("OrderData");
            setCartData(obj.message);
            setdiscountAmount(0);
            settotalAmount(0);
            setCartTotal(0.0);
            setCartItems(0);
            setMinusDisabled(false);
          } else {
            setCartData(obj.message);
            setdiscountAmount(obj.message[0].fld_cart_discount_amount);
            settotalAmount(obj.message[0].fld_cart_amount_no_shipping);
            setCartItems(obj.message[0].item_count);
            setAppliedCouponId(obj.message[0].fld_coupon_id)
            if (localStorage.getItem("LoginDetailsWeb")) {
              getAddress();
            }
            getOffer();
            setMinusDisabled(false);
            if (obj.message[0].fld_order_type) {
              setDeliveryType(obj.message[0].fld_order_type)
            }
            if (obj.message[0].fld_total_net_amount != null) {
              setCartTotal(obj.message[0].fld_total_net_amount.toFixed(2));
            }
            setCurrencyCode(obj.message[0].currency_code);
          }
          setCartData(obj.message);
          // getMenuList();
        }
      })
    );
  };

  const getMenuList = () => {
    var Order = localStorage.getItem("OrderData");
    var Orderdetails = JSON.parse(Order);
    PostApiCall.postRequest(
      {
        categoryid: null,
        orderid:
          localStorage.getItem("OrderData") == null ? 0 : Orderdetails.order_id,
      },
      "getFoodMenuOrder"
    ).then((results) =>
      results?.json().then((obj1) => {
        if (results.status == 200 || results.status == 201) {
          setcuisines(obj1.message);
          setallcuisines(obj1.message);
          //localStorage.setItem("BannerDetails", JSON.stringify(obj1.message))
          Notiflix.Loading.Remove();
        }
      })
    );
  };
  const getMenuData = (message) => {
    setcuisines(message);
    setallcuisines(message);
  };
  const getOffer = async () => {
    var login = localStorage.getItem("LoginDetailsWeb");
    var logindetails = login ? JSON.parse(login)[0] : null;

    var Order = localStorage.getItem("OrderData");
    var Orderdetails = JSON.parse(Order);

    PostApiCall.postRequest(
      {
        status: "Active",
      },
      "getOfferList"
    ).then((result) =>
      result?.json().then((obj) => {
        if (result.status == 200 || result.status == 201) {
          setOfferList(obj.message);
          // offerLis(obj.message);
        }
      })
    );
  };

  // get address
  const getAddress = async () => {
    var login = localStorage.getItem("LoginDetailsWeb");
    var logindetails = login ? JSON.parse(login)[0] : null;
    PostApiCall.postRequest(
      {
        columns: "*",
        whereClause: ` where fld_customerid = ${localStorage.getItem("LoginDetailsWeb") == null
          ? null
          : logindetails?.CustomerId
          }`,
      },
      "getCustomerAddress"
    ).then((result) =>
      result?.json().then((obj) => {
        if (result.status == 200 || result.status == 201) {
          setCustomerAddress(obj.message);
        }
      })
    );
  };

  function addAddress() {
    const login = localStorage.getItem("LoginDetailsWeb");
    var logindetails = login ? JSON.parse(login)[0] : null;

    PostApiCall.postRequest(
      {
        CUSTOMERID: logindetails != null ? logindetails?.CustomerId : 1,
        addressid: addressId,
        title: title,
        name: contactName,
        mobileNo: mobileNumber,
        area: mapAddress,
        flatno: confirmAddress,
        landmark: landmark,
        pincode: pincode,
        gstNumber: null,
        country: null,
        state: null,
        city: null,
        action: addressId == null ? "INSERT" : "Update",
        longitude: null,
        latitude: null,
      },
      "updatecustomeraddress"
    ).then((result) =>
      result?.json().then((obj) => {
        if (result.status == 200 || result.status == 201) {
          localStorage.removeItem("AddressData");
          setupdateCustomerAddressData(!updateCustomerAddressData);
          // setShow(false);
          setShowAddressModal(false);
          getAddress();
        } else {
          // Notiflix.Notify.Failure(obj.data);
        }
      })
    );
  }

  // dropdown date change
  function handleDateChange(e) {
    setSelectedDate(e.target.value);
  }

  // set ten days from now
  const calculateDateRange = () => {
    const minDate = new Date();
    const maxDate = new Date();
    maxDate.setDate(maxDate.getDate() + 9); // Set the maximum date to 10 days from today
    return { minDate, maxDate };
  };

  const handleViewMoreClick = () => {
    const { minDate, maxDate } = calculateDateRange();
    setCalendarDateRange({ minDate, maxDate });
  };

  const addtip = (tipamount) => {
    Notiflix.Loading.Dots("");
    PostApiCall.postRequest(
      {
        orderid:
          localStorage.getItem("OrderData") == null
            ? 0
            : JSON.parse(localStorage.getItem("OrderData")).order_id,
        tip: tipamount,
      },
      "AddTip"
    ).then((result) =>
      result.json().then((obj) => {
        // setLoadingSpinnerAddTip(false)
        if (result.status == 200 || result.status == 201) {
          getCart();
          setTipType("");
          setTip("");
          Notiflix.Loading.Remove();
        }
      })
    );
  };
  const UpdateTrxNumber = (tipamount) => {
    PostApiCall.postRequest(
      {
        orderid:
          localStorage.getItem("OrderData") == null
            ? 0
            : JSON.parse(localStorage.getItem("OrderData")).order_id,
      },
      "UpdateTrxNumber"
    ).then((result) =>
      result.json().then((obj) => {
        // setLoadingSpinnerAddTip(false)
        if (result.status == 200 || result.status == 201) {
          Notiflix.Loading.Remove();
        }
      })
    );
  };

  const SendAndroidPush = (message) => {
    var login = localStorage.getItem("LoginDetailsWeb");
    var logindetails = JSON.parse(login);
    var Order = localStorage.getItem("OrderData");
    var Orderdetails = JSON.parse(Order);
    PostApiCall.postRequest(
      {
        orderNumber: message.ordernumber,
        customerName: logindetails[0]?.fld_name,
        amount:
          cartData[0]?.currency_code + " " + cartData.length != 0 &&
            cartData[0]?.fld_cart_amount_no_shipping != null
            ? cartData[0]?.fld_cart_amount_no_shipping.toFixed(2)
            : 0.0,
        location: "",
        orderTime: message.ordertime,
        title: "Hi, you have a new order",
        body: `${logindetails[0]?.fld_name} placed an order,  Order Number: ${message.ordernumber}`,
      },
      "SendAndroidPush"
    ).then((result) =>
      result?.json().then((obj3) => {
        if (result.status == 200 || result.status == 201) {
          // addAddress();
          // updateOrderStatus();
          // localStorage.removeItem("OrderData");
          getCart();
          Notiflix.Notify.Success("Your order has been placed successfully");
          handlepaymentModalClose();
          handleClose();
          navigate("/payment-successful");
        } else {
          Notiflix.Notify.Failure("Oops!", "Something went wrong", "OK");
        }
      })
    );
  };
  const RemoveCoupon = () => {
    var login = localStorage.getItem("LoginDetailsWeb");
    var logindetails = login ? JSON.parse(login)[0] : null;
    PostApiCall.postRequest(
      {
        status: "unapply",
        couponid: cartData.length != 0 ? cartData[0].fld_coupon_id : "",
        orderid:
          localStorage.getItem("OrderData") == null
            ? 0
            : JSON.parse(localStorage.getItem("OrderData")).order_id,
        customerid:
          localStorage.getItem("LoginDetailsWeb") == null
            ? 1
            : logindetails?.CustomerId,
      },
      "ApplyOffer"
    ).then((result) =>
      result?.json().then((obj) => {
        if (result.status == 200 || result.status == 201) {
          Notiflix.Notify.Success("Coupon removed.");
          if (deliveryType == "Delivery") {
            PostApiCall.postRequest(
              {
                orderid: JSON.parse(
                  localStorage.getItem(
                    "OrderData"
                  )
                ).order_id,
                shippingcharge: "Add",
                ordertype: "Delivery",
              },
              "AddShipping"
            ).then((result) =>
              result?.json().then((obj3) => {
                if (
                  result.status == 200 ||
                  result.status == 201
                ) {
                  Notiflix.Loading.Remove();
                  // getCart();
                }
                Notiflix.Loading.Remove();
              })
            );
          }
        } else {
          Notiflix.Notify.Failure(obj.message);
        }

        getCart();
      })
    );
  };

  const freeShippingCouponsCount = offerList.filter(data => {
    if (deliveryType === "Delivery" && data.fld_minapplyamnt < cartData[0]?.fld_total_net_amount) {
      // Check if the offer is for free shipping and applicable for delivery
      if (data.fld_freeshipping === "Yes") {
        return true; // Offer for free shipping on delivery
      }
      else {
        // For delivery, show other coupons if minimum apply amount is less than cart amount
        return data.fld_minapplyamnt < cartData[0]?.fld_total_net_amount;
      }
    } else {
      // For take away, show coupons if minimum apply amount is less than cart amount
      return data.fld_minapplyamnt < cartData[0]?.fld_total_net_amount;
    }
  }
  ).length;


  const ApplyOffer = (couponid) => {
    var login = localStorage.getItem("LoginDetailsWeb");
    var logindetails = login ? JSON.parse(login)[0] : null;
    PostApiCall.postRequest(
      {
        status: "apply",
        couponid: couponid,
        orderid: localStorage.getItem("OrderData") == null ? 0 : JSON.parse(localStorage.getItem("OrderData")).order_id,
        customerid: localStorage.getItem("LoginDetailsWeb") == null ? 1 : logindetails.CustomerId,
      },
      "ApplyOffer"
    ).then((result) =>
      result?.json().then((obj) => {
        if (
          result.status == 200 ||
          result.status == 201
        ) {
          if (obj.message[0]?.error == "Coupon applied successfully") {
            setCouponAppliedPopup(true)
          }
          getCart();
        }
      })
    );
  }
  useEffect(() => {
    if (couponAppliedPopup == true) {
      setTimeout(() => {
        setCouponAppliedPopup(false)
      }, 3000);
    }
  }, [couponAppliedPopup])
  return (
    <>
      <div className="coupon-applied-box-overlay" style={{ display: couponAppliedPopup == true ? "block" : "none" }}></div>
      <div className="coupon-applied-box"
        style={{ display: couponAppliedPopup == true ? "block" : "none" }}
      >
        <img src={applycoupongif} />
        <div className="position-relative">
          <div className="icon">
            <FaCheck />
          </div>
          <h4 className="text-center">Coupon has been applied</h4>
          <p>Review your total at checkout.</p>
        </div>
      </div>
      <section
        // className={
        //   cartItems > 0
        //     ? "fixed-bottom fixed-bottom-cart-active mb-lg-3 mb-0 ms-auto px-2"
        //     : "fixed-bottom fixed-bottom-cart mb-lg-3 mb-0 ms-auto px-2"
        // }
        className="fixed-bottom mb-0 ms-auto px-2">
        <div>
          <CopyConfig
            show={showCopyConfig}
            close={() => setShowCopyConfig(false)}
          />
          <>
            <div className="d-flex flex-wrap flex-lg-nowrap justify-content-end align-items-center py-2">
              <div
                className={
                  scroll
                    ? "fixed-bottom-tabs fixed-bottom-tabs-active me-md-2 mt-1 mt-md-0"
                    : "fixed-bottom-tabs me-md-2 mt-1 mt-md-0"
                }>
                <Tabs
                  defaultActiveKey="1"
                  activeKey={activeTab}
                  items={menuListItems.map((data) => {
                    return {
                      key: data.fld_category_id,
                      label: data.fld_name,
                      // children: 'Content of Tab Pane 1',
                    };
                  })}
                  onChange={(key) => {
                    windowScrollY();
                    const selectedItem = menuListItems.find(
                      (item) => item.fld_category_id === key
                    );
                    setActiveTab(key);
                    if (selectedItem) {
                      setScrollTodiv(key);
                    }
                  }}
                />
                <Button
                  variant="danger"
                  size="lg"
                  className="floating-button floating-menu-button d-flex align-items-center"
                  onClick={handleShowMenu}>
                  <BiFoodMenu /> Menu
                </Button>
              </div>
              {loginDisabled === false ? (
                <Link
                  to="/dashboard"
                  className="floating-button btn btn-danger btn-lg text-white d-none d-md-flex me-md-2">
                  <VscGraph /> Dashboard
                </Link>
              ) : (
                ""
              )}
              {restaurantOpen == 'true' &&
                <Button
                  variant="danger"
                  size="lg"
                  className={
                    cartItems > 0
                      ? "floating-button-cart floating-button-cart-btn d-flex"
                      : "floating-button-cart d-flex"
                  }
                  onClick={() => {
                    setShow(true);
                    getCart();
                  }}>
                  <div>
                    <p className="mb-0 total-item-amount d-flex">
                      {cartItems} Item
                      <span
                        style={{ display: cartItems == 1 ? "none" : "block" }}>
                        (s)
                      </span>
                      &nbsp; | {currencyCode} {cartTotal} Plus Taxes
                      {/* <span className="seperator">|</span> */}
                    </p>
                  </div>
                  {/* <div className="total-item-amount d-flex justify-content-center align-items-center gap-1">
                  <FaShoppingBag /> View Order Summary
                </div> */}
                </Button>
              }
            </div>
          </>
        </div>
      </section>
      <Offcanvas
        className="backbtn-quickselection"
        show={show}
        onHide={handleClose}
        placement="end">
        <Offcanvas.Header>
          <Offcanvas.Title>
            <a onClick={handleClose} className="offcanvas-close cursor-pointer">
              <IoArrowUndo className="back-button" /> go back
            </a>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row>
            {/**=================this feature will added in future===================**/}
            {/* <Col lg={12}>
              <Alert variant="secondary">
                <div className="d-flex justify-content-start align-items-center gap-1">
                  <p className="saving">
                    <TiStopwatch className="save-thumb" /> Your order will be
                    delivered in
                  </p>
                  <p className="saving">
                    <span className="saving-amount">30 mins</span>
                  </p>
                </div>
              </Alert>
            </Col>
            <Col lg={12}>
              <Alert variant="secondary">
                <div className="d-flex justify-content-start align-items-center gap-1">
                  <p className="saving">
                    <TiStopwatch className="save-thumb" /> Your order can be
                    collected in
                  </p>
                  <p className="saving">
                    <span className="saving-amount">30 mins</span>
                  </p>
                </div>
              </Alert>
            </Col> */}
          </Row>
          <Row className="justify-content-center">
            <Col lg={12} className="mb-lg-4 mb-4">
              {/* cartData.length > 0 ? "3" : "7" */}
              <Accordion activeKey={defaultActiveKey}>
                {loginDisabled == true ? (
                  <>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header onClick={(prevstate) => {
                        let key = defaultActiveKey
                        setDefaultActiveKey(key == "3" ? "" : "3")
                      }}>
                        <FaShoppingCart className="accordion-icon" />
                        Item Cart
                      </Accordion.Header>
                      <Accordion.Body>
                        <Row>
                          <Col lg={12}>
                            <Card className="border-0">
                              <Card.Body className="p-0">
                                {cartData.length == 0 ? (
                                  <div>No Items Available in Cart</div>
                                ) : (
                                  cartData.map((Cuisine) => {
                                    return (
                                      <div className="d-flex justify-content-between align-items-start gap-2 mb-3">
                                        <div className="d-flex align-items-start gap-1">
                                          <div>
                                            <div className="d-flex align-items-center gap-1 recipe-best">
                                              <img
                                                src={
                                                  Cuisine.FLD_Food_Type ==
                                                    "Vegetarian"
                                                    ? Vegetarian
                                                    : Cuisine.FLD_Food_Type ==
                                                      "NonVegetarian"
                                                      ? NonVegetarian
                                                      : Vegan
                                                }
                                                className="recipe-type"
                                              />
                                              <FaPepperHot
                                                className={Cuisine.spice_level}
                                              />
                                              <span
                                                style={{
                                                  display:
                                                    Cuisine.contains_egg != null
                                                      ? "flex"
                                                      : "none",
                                                }}
                                                className="egg-recipe">
                                                {Cuisine.contains_egg}
                                              </span>
                                              <span
                                                style={{
                                                  display:
                                                    Cuisine.best_seller != null
                                                      ? "flex"
                                                      : "none",
                                                }}
                                                className="best-seller">
                                                {Cuisine.best_seller}
                                              </span>
                                            </div>
                                            <p className="mb-0 fw-bold">
                                              {Cuisine.fld_variantname}
                                            </p>
                                            {Cuisine.fld_complementry &&
                                              <div className="complementry-box complementry-box-cart">
                                                <span>
                                                  Complimentary{" "}
                                                  {/* <img
                                                    src={ComplementryIcon}
                                                    className="img-fluid"
                                                  /> */}
                                                </span>
                                                <p>{Cuisine.fld_complementry}</p>
                                              </div>}
                                            <p className="mb-0 small fw-bold d-flex justify-content-start align-items-center">
                                              {currencyCode}{" "}
                                              {
                                                Cuisine.fld_website_discount_price
                                              }
                                            </p>
                                            {Cuisine.ConfigDetails != "#" ? (
                                              <>
                                                {/* <a
                                                  href="#"
                                                  className="see-more small fw-bold">
                                                  Addon
                                                  <FaAngleDown />
                                                </a> */}
                                                {Cuisine.ConfigDetails.split(
                                                  "#"
                                                )
                                                  .filter((dat) => {
                                                    if (
                                                      dat.split(",")[7] !=
                                                      "properties"
                                                    ) {
                                                      return dat;
                                                    }
                                                  })
                                                  .map((config) => {
                                                    return (
                                                      <div className="d-flex justify-content-between align-items-center gap-2">
                                                        <div className="d-flex gap-2">
                                                          <h6 className="mb-0">
                                                            <img
                                                              src={
                                                                config != null
                                                                  ? config.split(
                                                                    ","
                                                                  )[3] ==
                                                                    "Vegetarian"
                                                                    ? Vegetarian
                                                                    : config.split(
                                                                      ","
                                                                    )[3] ==
                                                                      "NonVegetarian"
                                                                      ? NonVegetarian
                                                                      : Vegan
                                                                  : ""
                                                              }
                                                              className="recipe-type"
                                                            />{" "}
                                                            {config != null
                                                              ? config.split(
                                                                ","
                                                              )[1]
                                                              : ""}{" "}
                                                            (
                                                            {config != null
                                                              ? config.split(
                                                                ","
                                                              )[7]
                                                              : ""}
                                                            ) X{" "}
                                                            {config != null
                                                              ? config.split(
                                                                ","
                                                              )[5]
                                                              : ""}
                                                          </h6>
                                                        </div>
                                                        <div className="text-end">
                                                          <p className="mb-0 small fw-bold d-flex justify-content-start align-items-center">
                                                            {currencyCode}{" "}
                                                            {config != null
                                                              ? Number(
                                                                config.split(
                                                                  ","
                                                                )[2]
                                                              ).toFixed(2)
                                                              : ""}
                                                          </p>
                                                        </div>
                                                      </div>
                                                    );
                                                  })}
                                              </>
                                            ) : null}
                                          </div>
                                        </div>
                                        <div>
                                          <div className="text-end">
                                            <div className="mb-0 small fw-bold quantity-control">
                                              <Button
                                                className="quantity-btn"
                                                disabled={minusDisabled}
                                                onClick={() => {
                                                  setMinusDisabled(true);
                                                  setItemId(
                                                    Cuisine.FLD_Item_ID
                                                  );
                                                  setSelectedCartLineId(
                                                    Cuisine.fld_cart_dtl_id
                                                  );
                                                  setCurrentItemConfig(
                                                    Cuisine.ConfigDetails
                                                  );
                                                  var login =
                                                    localStorage.getItem(
                                                      "LoginDetailsWeb"
                                                    );
                                                  var logindetails =
                                                    JSON.parse(login);
                                                  var Order =
                                                    localStorage.getItem(
                                                      "OrderData"
                                                    );
                                                  var Orderdetails =
                                                    JSON.parse(Order);
                                                  // console.log(record.item_count);
                                                  if (Cuisine.item_count >= 1) {
                                                    // console.log("asdjadna ");
                                                    PostApiCall.postRequest(
                                                      {
                                                        orderdate:
                                                          moment().format(
                                                            "lll"
                                                          ),
                                                        itemdetails: `[{"Item":${Cuisine.FLD_Item_ID
                                                          },"Quantity":${-1},"SpiceLevel":${spiceLevel},"specialInstruction":"","addons":${null},"property":${null},"copyconfig":"No","cartlineid" :"${Cuisine.fld_cart_dtl_id
                                                          }"}]`,
                                                        status: "INCART",
                                                        customerid:
                                                          localStorage.getItem(
                                                            "LoginDetailsWeb"
                                                          ) == null
                                                            ? 1
                                                            : logindetails.CustomerId,
                                                        createdon:
                                                          moment().format(
                                                            "lll"
                                                          ),
                                                        updatedon:
                                                          moment().format(
                                                            "lll"
                                                          ),
                                                        orderid:
                                                          localStorage.getItem(
                                                            "OrderData"
                                                          ) == null
                                                            ? 0
                                                            : JSON.parse(
                                                              localStorage.getItem(
                                                                "OrderData"
                                                              )
                                                            ).order_id,
                                                        updatedby:
                                                          localStorage.getItem(
                                                            "LoginDetailsWeb"
                                                          ) == null
                                                            ? null
                                                            : logindetails.CustomerId,
                                                      },
                                                      "addToCart"
                                                    ).then((result) =>
                                                      result
                                                        .json()
                                                        .then((obj3) => {
                                                          // console.log(obj3.message);
                                                          if (
                                                            result.status ==
                                                            200 ||
                                                            result.status == 201
                                                          ) {
                                                            //setupdateCart(!updateCart);
                                                            setSelectedAddons(
                                                              []
                                                            );
                                                            setSelectedProperties(
                                                              []
                                                            );
                                                            if (
                                                              localStorage.getItem(
                                                                "OrderData"
                                                              ) == null
                                                            ) {
                                                              localStorage.setItem(
                                                                "OrderData",
                                                                JSON.stringify(
                                                                  obj3
                                                                    .message[0]
                                                                )
                                                              );
                                                            }
                                                          } else {
                                                            Notiflix.Notify.Failure(
                                                              obj3.message
                                                            );
                                                          }

                                                          // getmenulist(record.FLD_Cat_Id);
                                                          getCart();
                                                          getMenuData(
                                                            obj3.message
                                                          );
                                                        })
                                                    );

                                                    // }
                                                    setMinusDisabled(true);
                                                    if (
                                                      parseInt(
                                                        Cuisine.fld_quantity
                                                      ) == 1
                                                    ) {
                                                      PostApiCall.postRequest(
                                                        {
                                                          cartID: JSON.parse(
                                                            localStorage.getItem(
                                                              "OrderData"
                                                            )
                                                          ).order_id,
                                                          customerid:
                                                            localStorage.getItem(
                                                              "LoginDetailsWeb"
                                                            ) == null
                                                              ? 1
                                                              : logindetails.CustomerId,
                                                          cartlineid:
                                                            Cuisine.fld_cart_dtl_id,
                                                        },
                                                        "DeleteCartItem"
                                                      ).then((result) =>
                                                        result
                                                          .json()
                                                          .then((obj3) => {
                                                            if (
                                                              result.status ==
                                                              200 ||
                                                              result.status ==
                                                              201
                                                            ) {
                                                              //setupdateCart(!updateCart);

                                                              if (
                                                                Cuisine.length ==
                                                                0
                                                              ) {
                                                                localStorage.removeItem(
                                                                  "OrderData"
                                                                );
                                                              }
                                                              //getmenulist(record.FLD_Cat_Id);
                                                              getCart();
                                                              getMenuList();
                                                            } else {
                                                              Notiflix.Notify.Failure(
                                                                obj3.message
                                                              );
                                                            }
                                                          })
                                                      );
                                                    }
                                                  }
                                                }}>
                                                <FaMinus />
                                                {/* this button will be visible when user is not logged in */}
                                              </Button>
                                              <div className="px-1">
                                                {Cuisine.fld_quantity
                                                  .toString()
                                                  .padStart(2, 0)}
                                              </div>
                                              <Button
                                                className="quantity-btn"
                                                onClick={() => {
                                                  setItemId(
                                                    Cuisine.FLD_Item_ID
                                                  );
                                                  setCurrentItemConfig(
                                                    Cuisine.ConfigDetails
                                                  );
                                                  setSelectedCartLineId(
                                                    Cuisine.fld_cart_dtl_id
                                                  );
                                                  if (
                                                    Cuisine.ConfigDetails != "#"
                                                  ) {
                                                    setShowCopyConfig(true);
                                                  } else {
                                                    var login =
                                                      localStorage.getItem(
                                                        "LoginDetailsWeb"
                                                      );
                                                    var logindetails =
                                                      JSON.parse(login);
                                                    PostApiCall.postRequest(
                                                      {
                                                        orderdate:
                                                          moment().format(
                                                            "lll"
                                                          ),
                                                        itemdetails: `[{"Item":${Cuisine.FLD_Item_ID
                                                          },"Quantity":${1},"SpiceLevel":${spiceLevel},"specialInstruction":"","addons":${null},"property":${null},"copyconfig":"No","cartlineid" :"${Cuisine.fld_cart_dtl_id
                                                          }"}]`,
                                                        status: "INCART",
                                                        customerid:
                                                          localStorage.getItem(
                                                            "LoginDetailsWeb"
                                                          ) == null
                                                            ? null
                                                            : logindetails.CustomerId,
                                                        createdon:
                                                          moment().format(
                                                            "lll"
                                                          ),
                                                        updatedon:
                                                          moment().format(
                                                            "lll"
                                                          ),
                                                        orderid:
                                                          localStorage.getItem(
                                                            "OrderData"
                                                          ) == null
                                                            ? 0
                                                            : JSON.parse(
                                                              localStorage.getItem(
                                                                "OrderData"
                                                              )
                                                            ).order_id,
                                                        updatedby:
                                                          localStorage.getItem(
                                                            "LoginDetailsWeb"
                                                          ) == null
                                                            ? null
                                                            : logindetails.CustomerId,

                                                        stateid: null,
                                                      },
                                                      "addToCart"
                                                    ).then((result) =>
                                                      result
                                                        ?.json()
                                                        .then((obj) => {
                                                          if (
                                                            result.status ==
                                                            200 ||
                                                            result.status == 201
                                                          ) {
                                                            localStorage.setItem(
                                                              "OrderData",
                                                              JSON.stringify(
                                                                obj.message[0]
                                                              )
                                                            );
                                                            setSelectedAddons(
                                                              []
                                                            );
                                                            setSelectedProperties(
                                                              []
                                                            );
                                                          } else {
                                                            Notiflix.Notify.Failure(
                                                              obj.message
                                                            );
                                                          }
                                                          getMenuData(
                                                            obj.message
                                                          );
                                                          getCart();
                                                        })
                                                    );
                                                  }

                                                  setQuantityAdd(1);
                                                }}>
                                                <FaPlus />
                                              </Button>
                                            </div>
                                            {Cuisine.netvalue && (
                                              <p className="mb-0 small fw-bold d-flex justify-content-end align-items-center">
                                                {currencyCode}{" "}
                                                {Cuisine.netvalue}
                                              </p>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })
                                )}
                              </Card.Body>
                            </Card>
                          </Col>
                        </Row>
                      </Accordion.Body>
                    </Accordion.Item>
                  </>
                ) : (
                  <>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header onClick={() => {
                        let key = defaultActiveKey
                        setDefaultActiveKey(key == "0" ? "" : "0")
                      }}>
                        <FaAddressBook className="accordion-icon" />
                        Your Details
                      </Accordion.Header>
                      <Accordion.Body>
                        <Row>
                          {localStorage.getItem("LoginDetailsWeb") === null ? (
                            <>
                              <Col
                                lg={12}
                                style={{
                                  display:
                                    toggleOtp == false ? "block" : "none",
                                }}>
                                <FloatingLabel
                                  controlId="floatingName"
                                  label="Name"
                                  className="mb-3">
                                  <Form.Control
                                    type="text"
                                    placeholder="Full Name"
                                    onChange={(e) => {
                                      setName(e.target.value);
                                    }}
                                  />
                                </FloatingLabel>
                              </Col>
                              <Col
                                lg={12}
                                style={{
                                  display:
                                    toggleOtp == false ? "block" : "none",
                                }}>
                                <Row>
                                  <Col lg={12}>
                                    <FloatingLabel
                                      controlId="floatingContact"
                                      label="Mobile Number"
                                      className="mb-3">
                                      <Form.Control
                                        type="text"
                                        placeholder="Mobile Number"
                                        onChange={(e) => {
                                          setContact(e.target.value);
                                        }}
                                      />
                                    </FloatingLabel>
                                  </Col>
                                  {/* <Col lg={4}>
                                <Button
                                  variant="primary"
                                  type="submit"
                                  className="w-100 btn btn-danger btn-lg mb-3"
                                >
                                  Send OTP
                                </Button>
                              </Col> */}
                                </Row>
                              </Col>
                              <Col
                                lg={12}
                                style={{
                                  display: toggleOtp == true ? "block" : "none",
                                }}>
                                <Row>
                                  <Col lg={12}>
                                    <FloatingLabel
                                      controlId="floatingContact"
                                      label="Enter OTP"
                                      className="mb-3">
                                      <Form.Control
                                        type="text"
                                        placeholder="OTP"
                                        onChange={(e) => {
                                          setOtp(e.target.value);
                                        }}
                                      />
                                    </FloatingLabel>
                                  </Col>
                                  <Col lg={4}>
                                    <Button
                                      variant="primary"
                                      type="submit"
                                      className="w-100 btn btn-danger btn-lg mb-3"
                                      onClick={() => {
                                        if (
                                          document.cookie.split("$=$")[1] == otp
                                        ) {
                                          PostApiCall.postRequest(
                                            {
                                              name: name,
                                              email: emailAddress,
                                            },
                                            "getCustomerData"
                                          ).then((result) =>
                                            result?.json().then((obj3) => {
                                              if (
                                                result.status == 200 ||
                                                result.status == 201
                                              ) {
                                                localStorage.setItem(
                                                  "CustomerData",
                                                  JSON.stringify(obj3.message)
                                                );

                                                localStorage.setItem(
                                                  "LoginDetailsWeb",
                                                  JSON.stringify(obj3.message)
                                                );
                                                setCustomerData(obj3.message);
                                                Notiflix.Notify.Success(
                                                  "OTP validation successful"
                                                );
                                                setShow(false);
                                                // setToggleOtp(false);
                                              }
                                            })
                                          );
                                        } else {
                                          Notiflix.Notify.Failure(
                                            "Invalid OTP!"
                                          );
                                        }
                                      }}>
                                      Verify OTP
                                    </Button>
                                  </Col>
                                </Row>
                              </Col>

                              <Col
                                lg={12}
                                style={{
                                  display:
                                    toggleOtp == false ? "block" : "none",
                                }}>
                                <FloatingLabel
                                  controlId="floatingContact"
                                  label="Email Address"
                                  className="mb-3">
                                  <Form.Control
                                    type="email"
                                    placeholder="Email Address"
                                    onChange={(e) => {
                                      setEmailAddress(e.target.value);
                                    }}
                                  />
                                </FloatingLabel>
                              </Col>
                              <Col
                                lg={12}
                                style={{
                                  display:
                                    toggleOtp == false ? "block" : "none",
                                }}>
                                <Button
                                  variant="primary"
                                  type="submit"
                                  className="w-100 btn btn-danger btn-lg mb-3"
                                  onClick={() => {
                                    if (
                                      name != "" &&
                                      emailAddress != "" &&
                                      contact != ""
                                    ) {
                                      var newotp = Math.floor(
                                        100000 + Math.random() * 900000
                                      );
                                      document.cookie =
                                        "grubdigestotp$=$" + newotp;

                                      PostApiCall.postRequest(
                                        {
                                          name: name,
                                          email: emailAddress,
                                          mobile: contact,
                                          cartid: 0,
                                          otp: newotp,
                                        },
                                        "addcustomer"
                                      ).then((result) =>
                                        result?.json().then((obj3) => {
                                          if (
                                            result.status == 200 ||
                                            result.status == 201
                                          ) {
                                            Notiflix.Notify.Success(
                                              "OTP sent on your email successfully"
                                            );
                                            setToggleOtp(true);
                                          } else {
                                            Notiflix.Notify.Failure(
                                              "Error Occurred!"
                                            );
                                          }
                                        })
                                      );
                                    } else {
                                      Notiflix.Notify.Failure(
                                        "Please enter all mandatory fields!"
                                      );
                                    }
                                    // var Order = localStorage.getItem("OrderData");
                                    // var Orderdetails = JSON.parse(Order);
                                    // PostApiCall.postRequest(
                                    //   {
                                    //     name: name,
                                    //     email: emailAddress,
                                    //     mobile: contact,
                                    //     cartid:
                                    //       localStorage.getItem("OrderData") == null
                                    //         ? 0
                                    //         : JSON.parse(
                                    //             localStorage.getItem("OrderData")
                                    //           ).order_id,
                                    //   },
                                    //   "addcustomer"
                                    // ).then((result) =>
                                    //   result?.json().then((obj3) => {
                                    //     if (
                                    //       result.status == 200 ||
                                    //       result.status == 201
                                    //     ) {
                                    //       PostApiCall.postRequest(
                                    //         {
                                    //           name: name,
                                    //           email: emailAddress,
                                    //         },
                                    //         "getCustomerData"
                                    //       ).then((result) =>
                                    //         result?.json().then((obj3) => {
                                    //           if (
                                    //             result.status == 200 ||
                                    //             result.status == 201
                                    //           ) {
                                    //             localStorage.setItem(
                                    //               "CustomerData",
                                    //               JSON.stringify(obj3.message[0])
                                    //             );
                                    //             setCustomerData(
                                    //               JSON.parse(obj3.message[0])
                                    //             );
                                    //           }
                                    //         })
                                    //       );
                                    //     }
                                    //   })
                                    // );
                                  }}>
                                  Save
                                </Button>
                              </Col>
                            </>
                          ) : (
                            ""
                          )}
                          <p className="mb-1">
                            <strong>
                              {localStorage.getItem("LoginDetailsWeb") != null
                                ? JSON.parse(
                                  localStorage.getItem("LoginDetailsWeb")
                                )[0]?.fld_name
                                : ""}
                            </strong>
                          </p>
                          <p className="mb-1">
                            {localStorage.getItem("LoginDetailsWeb") != null ? (
                              <FaMobileAlt />
                            ) : null}
                            {localStorage.getItem("LoginDetailsWeb") != null
                              ? JSON.parse(
                                localStorage.getItem("LoginDetailsWeb")
                              )[0]?.fld_mobile
                              : ""}
                          </p>
                          <p className="mb-1">
                            {localStorage.getItem("LoginDetailsWeb") != null ? (
                              <BiMailSend />
                            ) : null}{" "}
                            {localStorage.getItem("LoginDetailsWeb") != null
                              ? JSON.parse(
                                localStorage.getItem("LoginDetailsWeb")
                              )[0].fld_email
                              : ""}
                          </p>
                        </Row>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1" >
                      <Accordion.Header onClick={() => {
                        let key = defaultActiveKey
                        setDefaultActiveKey(key == "1" ? "" : "1")
                      }}>
                        <FaShippingFast className="accordion-icon" />
                        Ordering Method
                      </Accordion.Header>
                      <Accordion.Body>
                        <Row>
                          <div className={`col-12 delivery-option-box ${deliveryType == "Delivery" ? 'active' : ""}`}>
                            {orderingMethods.map((om) => {
                              return om.fld_method_name != "Delivery" ? (
                                <div className={`delivery-option-btn delivery-option-btn-one`}>

                                  <Form>
                                    <div className="d-flex justify-content-center align-items-center gap-2">
                                      <Form.Check
                                        type="radio"
                                        checked={
                                          deliveryType == "TakeAway"
                                            ? true
                                            : false
                                        }
                                        onClick={() => {
                                          setDeliveryType(om.fld_method_name);
                                          Notiflix.Loading.Circle();
                                          sessionStorage.setItem("OrderType", 'TakeAway')
                                          PostApiCall.postRequest(
                                            {
                                              orderid: JSON.parse(
                                                localStorage.getItem(
                                                  "OrderData"
                                                )
                                              ).order_id,
                                              shippingcharge: "Remove",
                                              ordertype: "TakeAway",
                                            },
                                            "AddShipping"
                                          ).then((result) =>
                                            result?.json().then((obj3) => {
                                              if (
                                                result.status == 200 ||
                                                result.status == 201
                                              ) {
                                                Notiflix.Loading.Remove();
                                                getCart();
                                              }
                                              Notiflix.Loading.Remove();
                                            })
                                          );
                                        }}
                                      />
                                      <h6 className="mb-0 text-white">
                                        {om.fld_method_name == "TakeAway"
                                          ? "Takeout"
                                          : ""}
                                      </h6>
                                    </div>
                                  </Form>

                                </div>
                              ) : (
                                <div className={`delivery-option-btn`}>

                                  <Form>
                                    <div className="d-flex justify-content-center align-items-center gap-2">
                                      <Form.Check
                                        type="radio"
                                        checked={
                                          deliveryType == "Delivery"
                                            ? true
                                            : false
                                        }
                                        onClick={() => {
                                          setDeliveryType(
                                            om.fld_method_name
                                          );
                                          sessionStorage.setItem("OrderType", 'Delivery')
                                          getAddress();
                                          Notiflix.Loading.Circle();
                                          PostApiCall.postRequest(
                                            {
                                              orderid: JSON.parse(
                                                localStorage.getItem(
                                                  "OrderData"
                                                )
                                              ).order_id,
                                              shippingcharge: "Add",
                                              ordertype: "Delivery",
                                            },
                                            "AddShipping"
                                          ).then((result) =>
                                            result?.json().then((obj3) => {
                                              if (
                                                result.status == 200 ||
                                                result.status == 201
                                              ) {
                                                Notiflix.Loading.Remove();
                                                getCart();
                                              }
                                              Notiflix.Loading.Remove();
                                            })
                                          );
                                        }}
                                      />
                                      <h6 className="mb-0">
                                        {om.fld_method_name}
                                      </h6>
                                    </div>

                                  </Form>

                                </div>
                              );
                            })}
                          </div>
                          <div className="col-12 text-end">
                            {deliveryType == "Delivery" &&
                              <button
                                type="button"
                                className="border-0 add-address-offcanvas-btn"
                                onClick={handleShowModal}>
                                Add New Address
                              </button>}
                          </div>
                          {customerAddress.length > 0 &&
                            <>
                              <p
                                style={{
                                  display:
                                    deliveryType == "Delivery" ? "block" : "none",
                                }}
                                className="fw-bold">
                                Select Delivery Address
                              </p>
                              <Col
                                className="address-list"
                                style={{
                                  display:
                                    deliveryType == "Delivery" ? "block" : "none",
                                }}>
                                {/* <Carousel
                          responsive={responsive}
                          swipeable={true}
                          draggable={true}
                          showDots={true}
                          infinite={false}
                          autoPlay={false}
                        > */}
                                <Swiper
                                  //   freeMode={true}
                                  loop={true}
                                  breakpoints={{
                                    640: {
                                      slidesPerView: 1,
                                      spaceBetween: 20,
                                    },
                                    768: {
                                      slidesPerView: 1,
                                      spaceBetween: 20,
                                    },
                                    1024: {
                                      slidesPerView: 2,
                                      spaceBetween: 20,
                                    },
                                  }}
                                  pagination={{
                                    clickable: true,
                                  }}
                                  modules={[FreeMode, Pagination]}
                                  className="mySwiper pb-5 pt-3">
                                  {customerAddress.map((Address) => {
                                    return (
                                      <SwiperSlide
                                        style={{
                                          display:
                                            deliveryType == "Delivery"
                                              ? "block"
                                              : "none",
                                        }}>
                                        <Card className="border shadow-sm address-card">
                                          <Card.Body>
                                            <p className="mb-0 fw-bold">
                                              {Address.fld_address_type}
                                            </p>
                                            <p className="mb-0">
                                              {Address.fld_address_1}
                                            </p>
                                            <p className="mb-0">
                                              {Address.fld_address_2}
                                            </p>
                                            <p className="mb-0">
                                              {Address.fld_pincode}
                                            </p>
                                            <p className="mb-0 fw-bold">
                                              {Address.fld_contact_name}
                                            </p>
                                            <p className="mb-0 fw-bold">
                                              {Address.fld_contact_number}
                                            </p>
                                          </Card.Body>
                                          <span className="selected-address">
                                            <Form.Check
                                              className="add-address-offcanva-checkbox"
                                              checked={
                                                deliveryAddress ==
                                                  Address.fld_address_id
                                                  ? true
                                                  : false
                                              }
                                              onClick={() => {
                                                setDeliveryAddress(
                                                  Address.fld_address_id
                                                );
                                                setAddressId(
                                                  Address.fld_address_id
                                                );
                                                setAddress1(Address.fld_address_1);
                                                setAddress2(Address.fld_address_2);
                                              }}
                                            />
                                            {/* <FaCheckCircle
                                        className="theme-red"
                                        onClick={() => {
                                          setDeliveryAddress(
                                            Address.fld_address_id
                                          );
                                        }}
                                      /> */}
                                          </span>
                                        </Card>
                                      </SwiperSlide>
                                    );
                                  })}
                                </Swiper>
                                {/* </Carousel> */}
                              </Col>
                            </>
                          }
                        </Row>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header onClick={(prevstate) => {
                        let key = defaultActiveKey
                        setDefaultActiveKey(key == "2" ? "" : "2")
                      }}>
                        <FaCalendarAlt className="accordion-icon" />
                        Schedule Order
                      </Accordion.Header>
                      <Accordion.Body>
                        <Row>
                          <Col lg={12}>
                            <Card className="mb-lg-0 mb-0">
                              <Card.Body className="px-0 py-lg-2 py-2">
                                <Form>
                                  <div className="d-flex justify-content-start align-items-center gap-2">
                                    <Form.Check
                                      className="add-address-offcanva-checkbox"
                                      checked={
                                        scheduleOrder == "Now" ? true : false
                                      }
                                      onClick={() => {
                                        setScheduleOrder("Now");
                                        setScheduleOrderDate(null);
                                        setScheduleOrderTime(null);
                                      }}
                                    />
                                    <h6 className="mb-0">Now</h6>
                                  </div>
                                </Form>
                              </Card.Body>
                            </Card>
                          </Col>
                          {/* <Col lg={12}>
                            <Card>
                              <Card.Body className="px-0 py-lg-2 py-2">
                                <Form>
                                  <div className="d-flex justify-content-between align-items-center gap-2">
                                    <div className="d-flex justify-content-start align-items-center gap-2">
                                      <Form.Check
                                        checked={
                                          scheduleOrder == "Later"
                                            ? true
                                            : false
                                        }
                                        onClick={() => {
                                          setScheduleOrder("Later");
                                        }}
                                      />
                                      <h6 className="mb-0">Later</h6>
                                    </div>
                                  </div>
                                </Form>
                              </Card.Body>
                            </Card>
                          </Col> */}
                        </Row>
                        <Row
                          style={{
                            display:
                              scheduleOrder == "Later" ? "block" : "none",
                          }}>
                          <Col lg={12} className="mb-lg-3 mb-3 ">
                            <div className="form-group ">
                              {/*  */}
                              {showCalendar === true ? (
                                <Calendar
                                  placeholderText="Date"
                                  dateFormat="dd/MM/yyyy"
                                  selected={scheduleOrderDate}
                                  onChange={(date) => {
                                    setScheduleOrderDate(date);
                                    setShowCalendar(!showCalendar);
                                  }}
                                  // isClearable
                                  // maxDate={new Date()}
                                  minDate={calendarDateRange.minDate}
                                  maxDate={calendarDateRange.maxDate}
                                />
                              ) : (
                                ""
                              )}
                            </div>
                            {/* <span
                          className="login-icon-change-pass"
                          style={{ bottom: "30px" }}
                        >
                          <img
                            src={Calendericon}
                            onClick={() => {
                              setShowCalendar(!showCalendar);

                            }}
                            alt="datepicker"
                            className="btnicons"
                          ></img>{" "}
                          Select Delivery Date
                        </span> */}
                            <div className="d-flex align-items-center mt-3 calender-style">
                              <p className="me-2 mt-2">Select Delivery Date</p>
                              <FormControl
                                className="date-dropdown-style"
                                required>
                                <Select
                                  value={selectedDate}
                                  onChange={(e) => {
                                    setSelectedDate(e.target.value);
                                    setScheduleOrderDate(
                                      new Date(e.target.value)
                                    );
                                  }}>
                                  <MenuItem value="" selected>
                                    Select Date
                                  </MenuItem>
                                  {dateOptions.map((date, index) => (
                                    <MenuItem key={index} value={date}>
                                      {date}
                                    </MenuItem>
                                  ))}

                                  <MenuItem>
                                    <button
                                      className="border-0"
                                      type="btn"
                                      onClick={() => {
                                        setShowCalendar(!showCalendar);
                                        handleViewMoreClick();
                                      }}>
                                      View More
                                    </button>
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </div>
                          </Col>
                          <Col lg={12}>
                            <div className="form-group ">
                              {/* <TimePicker
                            placeholderText="Time"
                            onChange={(date) => {
                              setScheduleOrderTime(date);
                            }}
                            // isClearable
                            maxDate={new Date()}
                          /> */}
                              <Space wrap>
                                Select Time
                                <TimePicker
                                  use12Hours
                                  format="h:mm A"
                                  onChange={onChange}
                                />
                              </Space>
                            </div>
                          </Col>
                        </Row>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header onClick={() => {
                        let key = defaultActiveKey
                        setDefaultActiveKey(key == "3" ? "" : "3")
                      }}>
                        <FaShoppingCart className="accordion-icon" />
                        Item Cart
                      </Accordion.Header>
                      <Accordion.Body>
                        <Row>
                          <Col lg={12}>
                            <Card className="border-0">
                              <Card.Body className="p-0">
                                {cartData.length == 0 ? (
                                  <div>No Items Available in Cart</div>
                                ) : (
                                  cartData.map((Cuisine) => {
                                    return (
                                      <div className="d-flex justify-content-between align-items-start gap-2 mb-3">
                                        <div className="d-flex align-items-start gap-1">
                                          <div>
                                            <div className="d-flex align-items-center gap-1 recipe-best">
                                              <img
                                                src={
                                                  Cuisine.FLD_Food_Type ==
                                                    "Vegetarian"
                                                    ? Vegetarian
                                                    : Cuisine.FLD_Food_Type ==
                                                      "NonVegetarian"
                                                      ? NonVegetarian
                                                      : Vegan
                                                }
                                                className="recipe-type"
                                              />
                                              {Cuisine.spice_level &&
                                                <FaPepperHot
                                                  className={Cuisine.spice_level}
                                                />}
                                              <span
                                                style={{
                                                  display:
                                                    Cuisine.contains_egg != null
                                                      ? "flex"
                                                      : "none",
                                                }}
                                                className="egg-recipe">
                                                {Cuisine.contains_egg}
                                              </span>
                                              <span
                                                style={{
                                                  display:
                                                    Cuisine.best_seller != null
                                                      ? "flex"
                                                      : "none",
                                                }}
                                                className="best-seller">
                                                {Cuisine.best_seller}
                                              </span>
                                            </div>
                                            <p className="mb-0 fw-bold">
                                              {Cuisine.fld_variantname}
                                            </p>
                                            {Cuisine.fld_complementry &&
                                              <div className="complementry-box complementry-box-cart">
                                                <span>
                                                  Complimentary{" "}
                                                  {/* <img
                                                    src={ComplementryIcon}
                                                    className="img-fluid"
                                                  /> */}
                                                </span>
                                                <p>{Cuisine.fld_complementry}</p>
                                              </div>
                                            }{Cuisine.fld_website_discount_price > 0 &&
                                              <p className="mb-0 small fw-bold d-flex justify-content-start align-items-center">
                                                {currencyCode}{" "}
                                                {
                                                  Cuisine.fld_website_discount_price
                                                }
                                              </p>}

                                            {Cuisine.ConfigDetails != "#" ? (
                                              <>
                                                {/* <a
                                                  href="#"
                                                  className="see-more small fw-bold">
                                                  Addon
                                                  <FaAngleDown />
                                                </a> */}
                                                {Cuisine.ConfigDetails.split(
                                                  "#"
                                                )
                                                  .filter((dat) => {
                                                    if (
                                                      dat.split(",")[7] !=
                                                      "properties"
                                                    ) {
                                                      return dat;
                                                    }
                                                  })
                                                  .map((config) => {
                                                    return (
                                                      <div className="d-flex justify-content-between align-items-center gap-2">
                                                        <div className="d-flex gap-2">
                                                          <h6 className="mb-0">
                                                            <img
                                                              src={
                                                                config != null
                                                                  ? config.split(
                                                                    ","
                                                                  )[3] ==
                                                                    "Vegetarian"
                                                                    ? Vegetarian
                                                                    : config.split(
                                                                      ","
                                                                    )[3] ==
                                                                      "NonVegetarian"
                                                                      ? NonVegetarian
                                                                      : Vegan
                                                                  : ""
                                                              }
                                                              className="recipe-type"
                                                            />{" "}
                                                            {/* {config != null
                                                            ? config.split(
                                                              ","
                                                            )[1]
                                                            : ""} */}
                                                            {config != null
                                                              ? config.split(
                                                                ","
                                                              )[1]
                                                              : ""}{" "}
                                                            (
                                                            {config != null
                                                              ? config.split(
                                                                ","
                                                              )[7]
                                                              : ""}
                                                            ) X{" "}
                                                            {config != null
                                                              ? config.split(
                                                                ","
                                                              )[5]
                                                              : ""}
                                                          </h6>
                                                        </div>
                                                        <div className="text-end">
                                                          <p className="mb-0 small fw-bold d-flex justify-content-start align-items-center">
                                                            {currencyCode}{" "}
                                                            {config != null
                                                              ? Number(
                                                                config.split(
                                                                  ","
                                                                )[2]
                                                              ).toFixed(2)
                                                              : ""}
                                                          </p>
                                                        </div>
                                                      </div>
                                                    );
                                                  })}
                                              </>
                                            ) : null}
                                          </div>
                                        </div>
                                        {Cuisine.fld_website_discount_price > 0 ?
                                          <div>
                                            <div className="text-end">
                                              <div className="mb-0 small fw-bold quantity-control">
                                                <Button
                                                  className="quantity-btn"
                                                  disabled={minusDisabled}
                                                  onClick={() => {
                                                    setMinusDisabled(true);
                                                    setItemId(
                                                      Cuisine.FLD_Item_ID
                                                    );
                                                    setSelectedCartLineId(
                                                      Cuisine.fld_cart_dtl_id
                                                    );
                                                    setCurrentItemConfig(
                                                      Cuisine.ConfigDetails
                                                    );
                                                    var login =
                                                      localStorage.getItem(
                                                        "LoginDetailsWeb"
                                                      );
                                                    var logindetails =
                                                      JSON.parse(login);
                                                    var Order =
                                                      localStorage.getItem(
                                                        "OrderData"
                                                      );
                                                    var Orderdetails =
                                                      JSON.parse(Order);
                                                    // console.log(record.item_count);
                                                    if (Cuisine.item_count >= 1) {
                                                      // console.log("asdjadna ");
                                                      PostApiCall.postRequest(
                                                        {
                                                          orderdate:
                                                            moment().format(
                                                              "lll"
                                                            ),
                                                          itemdetails: `[{"Item":${Cuisine.FLD_Item_ID
                                                            },"Quantity":${-1},"SpiceLevel":${spiceLevel},"specialInstruction":"","addons":${null},"property":${null},"copyconfig":"No","cartlineid" :"${Cuisine.fld_cart_dtl_id
                                                            }"}]`,
                                                          status: "INCART",
                                                          customerid:
                                                            localStorage.getItem(
                                                              "LoginDetailsWeb"
                                                            ) == null
                                                              ? 1
                                                              : logindetails.CustomerId,
                                                          createdon:
                                                            moment().format(
                                                              "lll"
                                                            ),
                                                          updatedon:
                                                            moment().format(
                                                              "lll"
                                                            ),
                                                          orderid:
                                                            localStorage.getItem(
                                                              "OrderData"
                                                            ) == null
                                                              ? 0
                                                              : JSON.parse(
                                                                localStorage.getItem(
                                                                  "OrderData"
                                                                )
                                                              ).order_id,
                                                          updatedby:
                                                            localStorage.getItem(
                                                              "LoginDetailsWeb"
                                                            ) == null
                                                              ? null
                                                              : logindetails.CustomerId,
                                                        },
                                                        "addToCart"
                                                      ).then((result) =>
                                                        result
                                                          .json()
                                                          .then((obj3) => {
                                                            // console.log(obj3.message);
                                                            if (
                                                              result.status ==
                                                              200 ||
                                                              result.status == 201
                                                            ) {
                                                              //setupdateCart(!updateCart);
                                                              setSelectedAddons([]);
                                                              setSelectedProperties([]);
                                                              if (localStorage.getItem("OrderData") == null) {
                                                                localStorage.setItem(
                                                                  "OrderData",
                                                                  JSON.stringify(
                                                                    obj3
                                                                      .message[0]
                                                                  )
                                                                );
                                                              }
                                                              if (Cuisine.fld_coupon_id) {
                                                                ApplyOffer(Cuisine.fld_coupon_id)
                                                              }
                                                            } else {
                                                              Notiflix.Notify.Failure(
                                                                obj3.message
                                                              );
                                                            }

                                                            // getmenulist(record.FLD_Cat_Id);
                                                            getCart();
                                                            getMenuData(
                                                              obj3.message
                                                            );
                                                            if (
                                                              cartData[0]
                                                                ?.fld_coupon_code !=
                                                              null
                                                            ) {
                                                              const appliedcoupon =
                                                                offerList.filter(
                                                                  (coupon) =>
                                                                    cartData[0]
                                                                      ?.fld_coupon_code ==
                                                                    coupon.fld_code
                                                                );
                                                              if (
                                                                cartData[0]
                                                                  ?.fld_cart_amount !=
                                                                null &&
                                                                cartData[0]
                                                                  ?.fld_cart_amount <
                                                                appliedcoupon[0]
                                                                  ?.fld_minapplyamnt
                                                              ) {
                                                                RemoveCoupon();
                                                              }
                                                            }
                                                          })
                                                      );
                                                      setMinusDisabled(true);
                                                      // }
                                                      if (
                                                        parseInt(
                                                          Cuisine.fld_quantity
                                                        ) == parseInt("1")
                                                      ) {
                                                        PostApiCall.postRequest(
                                                          {
                                                            cartID: JSON.parse(
                                                              localStorage.getItem(
                                                                "OrderData"
                                                              )
                                                            ).order_id,
                                                            customerid:
                                                              localStorage.getItem(
                                                                "LoginDetailsWeb"
                                                              ) == null
                                                                ? 1
                                                                : logindetails.CustomerId,
                                                            cartlineid:
                                                              Cuisine.fld_cart_dtl_id,
                                                          },
                                                          "DeleteCartItem"
                                                        ).then((result) =>
                                                          result
                                                            .json()
                                                            .then((obj3) => {
                                                              if (
                                                                result.status ==
                                                                200 ||
                                                                result.status ==
                                                                201
                                                              ) {
                                                                //setupdateCart(!updateCart);

                                                                if (
                                                                  Cuisine.length ==
                                                                  0
                                                                ) {
                                                                  localStorage.removeItem(
                                                                    "OrderData"
                                                                  );
                                                                }
                                                                //getmenulist(record.FLD_Cat_Id);
                                                                getCart();
                                                                getMenuList();
                                                              } else {
                                                                Notiflix.Notify.Failure(
                                                                  obj3.message
                                                                );
                                                              }
                                                            })
                                                        );
                                                      }
                                                    }
                                                  }}>
                                                  <FaMinus />
                                                  {/* this button will be visible when user is not logged in */}
                                                </Button>
                                                <div className="px-1">
                                                  {Cuisine.fld_quantity
                                                    ?.toString()
                                                    ?.padStart(2, 0)}
                                                </div>
                                                <Button
                                                  className="quantity-btn"
                                                  onClick={() => {
                                                    setItemId(
                                                      Cuisine.FLD_Item_ID
                                                    );
                                                    setCurrentItemConfig(
                                                      Cuisine.ConfigDetails
                                                    );
                                                    setSelectedCartLineId(
                                                      Cuisine.fld_cart_dtl_id
                                                    );
                                                    if (
                                                      Cuisine.ConfigDetails != "#"
                                                    ) {
                                                      setShowCopyConfig(true);
                                                    } else {
                                                      var login =
                                                        localStorage.getItem(
                                                          "LoginDetailsWeb"
                                                        );
                                                      var logindetails =
                                                        JSON.parse(login);
                                                      PostApiCall.postRequest(
                                                        {
                                                          orderdate:
                                                            moment().format(
                                                              "lll"
                                                            ),
                                                          itemdetails: `[{"Item":${Cuisine.FLD_Item_ID
                                                            },"Quantity":${1},"SpiceLevel":${spiceLevel},"specialInstruction":"","addons":${null},"property":${null},"copyconfig":"No","cartlineid" :"${Cuisine.fld_cart_dtl_id
                                                            }"}]`,
                                                          status: "INCART",
                                                          customerid:
                                                            localStorage.getItem(
                                                              "LoginDetailsWeb"
                                                            ) == null
                                                              ? null
                                                              : logindetails.CustomerId,
                                                          createdon:
                                                            moment().format(
                                                              "lll"
                                                            ),
                                                          updatedon:
                                                            moment().format(
                                                              "lll"
                                                            ),
                                                          orderid:
                                                            localStorage.getItem(
                                                              "OrderData"
                                                            ) == null
                                                              ? 0
                                                              : JSON.parse(
                                                                localStorage.getItem(
                                                                  "OrderData"
                                                                )
                                                              ).order_id,
                                                          updatedby:
                                                            localStorage.getItem(
                                                              "LoginDetailsWeb"
                                                            ) == null
                                                              ? null
                                                              : logindetails.CustomerId,

                                                          stateid: null,
                                                        },
                                                        "addToCart"
                                                      ).then((result) =>
                                                        result
                                                          ?.json()
                                                          .then((obj) => {
                                                            if (
                                                              result.status ==
                                                              200 ||
                                                              result.status == 201
                                                            ) {
                                                              localStorage.setItem(
                                                                "OrderData",
                                                                JSON.stringify(
                                                                  obj.message[0]
                                                                )
                                                              );
                                                              setSelectedAddons(
                                                                []
                                                              );
                                                              setSelectedProperties(
                                                                []
                                                              );
                                                              if (Cuisine.fld_coupon_id) {
                                                                ApplyOffer(Cuisine.fld_coupon_id)
                                                              }
                                                            } else {
                                                              Notiflix.Notify.Failure(
                                                                obj.message
                                                              );
                                                            }
                                                            getMenuData(
                                                              obj.message
                                                            );
                                                            getCart();
                                                          })
                                                      );
                                                    }

                                                    setQuantityAdd(1);
                                                  }}>
                                                  <FaPlus />
                                                </Button>
                                              </div>
                                              {Cuisine.netvalue && (
                                                <p className="mb-0 small fw-bold d-flex justify-content-end align-items-center">
                                                  {currencyCode}{" "}
                                                  {Cuisine.netvalue}
                                                </p>
                                              )}
                                            </div>
                                          </div>
                                          :
                                          <div className="text-end">
                                            <div className="mb-0 small fw-bold quantity-control justify-content-center free-btn">
                                              free
                                            </div>
                                          </div>}
                                      </div>
                                    );
                                  })
                                )}
                              </Card.Body>
                            </Card>
                          </Col>
                        </Row>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                      <Accordion.Header onClick={() => {
                        let key = defaultActiveKey
                        setDefaultActiveKey(key == "4" ? "" : "4")
                      }}>
                        <FaRegCommentDots className="accordion-icon" />
                        Additional Instructions
                      </Accordion.Header>
                      <Accordion.Body>
                        <Form>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1">
                            {/* <Form.Label
                          dangerouslySetInnerHTML={{
                            __html:
                              keyStrings?.filter(
                                (item) =>
                                  item.FLD_Key_Name ==
                                  "QUICK_SEL_CANCELLATION_FEES"
                              )[0] != null
                                ? keyStrings?.filter(
                                    (item) =>
                                      item.FLD_Key_Name ==
                                      "QUICK_SEL_CANCELLATION_FEES"
                                  )[0].FLD_Key_Value
                                : "<div></div>",
                          }}
                        ></Form.Label> */}
                            <Form.Control
                              onChange={(e) => {
                                setadditionalInstructions(e.target.value);
                              }}
                              as="textarea"
                              rows={3}
                              placeholder="Write your instruction here..."
                            />
                          </Form.Group>
                        </Form>
                      </Accordion.Body>
                    </Accordion.Item>
                    {deliveryType == "Delivery" &&
                      <Accordion.Item eventKey="5">
                        <Accordion.Header onClick={() => {
                          let key = defaultActiveKey
                          setDefaultActiveKey(key == "5" ? "" : "5")
                        }}>
                          <FaShippingFast className="accordion-icon" />
                          Delivery Instructions
                        </Accordion.Header>
                        <Accordion.Body>
                          <Form>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlTextarea1">
                              <Form.Label
                                dangerouslySetInnerHTML={{
                                  __html:
                                    keyStrings?.filter(
                                      (item) =>
                                        item.FLD_Key_Name ==
                                        "QUICK_SEL_DELIVERY_INSTRUCTION"
                                    )[0] != null
                                      ? keyStrings?.filter(
                                        (item) =>
                                          item.FLD_Key_Name ==
                                          "QUICK_SEL_DELIVERY_INSTRUCTION"
                                      )[0].FLD_Key_Value
                                      : "<div></div>",
                                }}></Form.Label>
                              <Form.Control
                                onChange={(e) => {
                                  setDeliveryInstructions(e.target.value);
                                }}
                                as="textarea"
                                rows={3}
                                placeholder="Write your instruction here..."
                              />
                            </Form.Group>
                          </Form>
                        </Accordion.Body>
                      </Accordion.Item>
                    }
                    <Accordion.Item eventKey="6" className={freeShippingCouponsCount > 0 ? "coupon-accordian" : ""}>
                      <Accordion.Header onClick={() => {
                        let key = defaultActiveKey
                        setDefaultActiveKey(key == "6" ? "" : "6")
                      }}>
                        <div><FaTicketAlt className="accordion-icon" /> Use Coupons ( <strong>{offerList.length} Coupons Available</strong> )</div>
                        <div className={freeShippingCouponsCount > 0 ? "w-100 mt-3 mb-2" : ""}>
                          {offerList.filter((data) => {
                            if (deliveryType === "Delivery" && data.fld_minapplyamnt < cartData[0]?.fld_total_net_amount) {
                              // Check if the offer is for free shipping and applicable for delivery
                              if (data.fld_freeshipping === "Yes") {
                                return true; // Offer for free shipping on delivery
                              }
                              else {
                                // For delivery, show other coupons if minimum apply amount is less than cart amount
                                return data.fld_minapplyamnt < cartData[0]?.fld_total_net_amount;
                              }
                            } else {
                              // For take away, show coupons if minimum apply amount is less than cart amount
                              return data.fld_minapplyamnt < cartData[0]?.fld_total_net_amount;
                            }
                          }).slice(0, 1).map((ol) => {
                            return (
                              <>
                                <Card className="shadow-sm border mb-2">
                                  <Card.Body className="coupon-card-body">
                                    <Card.Title className="fw-bold mb-0">{ol.fld_name}</Card.Title>
                                    <p className="small mt-2 mb-3">
                                      {ol.fld_caption}
                                    </p>
                                    <div className="d-flex justify-content-between align-items-center">
                                      <p className="coupon-code p-2">
                                        {" "}
                                        {ol.fld_code}
                                      </p>
                                      {cartData[0].fld_total_net_amount <
                                        ol.fld_minapplyamnt ? (
                                        <span className="coupon-code-text">{`Add items worth ${currencyCode} ${(
                                          ol.fld_minapplyamnt -
                                          cartData[0].fld_total_net_amount
                                        ).toFixed(
                                          2
                                        )} more to unlock`}</span>
                                      ) :
                                        // cartData[0]?.fld_coupon_discount > 0 &&
                                        // cartData[0]?.fld_coupon_discount != null
                                        cartData[0]?.fld_coupon_id == ol.fld_offerid
                                          ? (
                                            <Button
                                              onClick={(e) => {
                                                RemoveCoupon();
                                              }}
                                              variant="danger"
                                              size="sm">
                                              Remove Coupon
                                            </Button>
                                          ) : (
                                            <Button
                                              disabled={
                                                cartData[0]
                                                  ?.fld_coupon_discount > 0
                                              }
                                              onClick={(e) => {
                                                var login =
                                                  localStorage.getItem(
                                                    "LoginDetailsWeb"
                                                  );
                                                var logindetails = login
                                                  ? JSON.parse(login)[0]
                                                  : null;
                                                PostApiCall.postRequest(
                                                  {
                                                    status: "apply",
                                                    couponid: ol.fld_offerid,
                                                    orderid:
                                                      localStorage.getItem(
                                                        "OrderData"
                                                      ) == null
                                                        ? 0
                                                        : JSON.parse(
                                                          localStorage.getItem(
                                                            "OrderData"
                                                          )
                                                        ).order_id,
                                                    customerid:
                                                      localStorage.getItem(
                                                        "LoginDetailsWeb"
                                                      ) == null
                                                        ? 1
                                                        : logindetails.CustomerId,
                                                  },
                                                  "ApplyOffer"
                                                ).then((result) =>
                                                  result?.json().then((obj) => {
                                                    if (
                                                      result.status == 200 ||
                                                      result.status == 201
                                                    ) {
                                                      if (obj.message[0]?.error == "Coupon applied successfully") {
                                                        Notiflix.Notify.Success(obj.message[0]?.error);
                                                      } else {
                                                        Notiflix.Notify.Failure(obj.message[0]?.error);
                                                      }
                                                      // setAppliedCoupon(ol)
                                                      // Notiflix.Notify.Success(
                                                      //   "Coupon applied."
                                                      // );
                                                    } else {
                                                      Notiflix.Notify.Failure(
                                                        obj.message
                                                      );
                                                    }

                                                    getCart();
                                                  })
                                                );
                                              }}
                                              variant="danger"
                                              size="sm">
                                              Apply Coupon
                                            </Button>
                                          )}
                                    </div>
                                  </Card.Body>
                                </Card>
                              </>
                            );
                          })}
                        </div>
                        {freeShippingCouponsCount > 0 &&
                          <p className="mb-0 view-all-coupon-text ">View All Coupons</p>}
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="col-12 mb-2 apply-coupon-input">
                          <input
                            type="text"
                            placeholder="Enter Coupon Code"
                            className="form-control mb-0"
                            value={enteredCouponCode}
                            onChange={(enteredcouponcode) => {
                              setEnteredCouponCode(enteredcouponcode.target.value);
                            }}></input>
                          <p className="invalid-coupon-code-textbox coupon-code-text mt-2 mb-0">
                            {couponErrorCodeTextBox}
                          </p>
                        </div>
                        <div className="col-12 apply-coupon-button">
                          <button
                            className="btn btn-danger border-0 my-3 w-100 text-center"
                            onClick={() => {
                              var login = localStorage.getItem("LoginDetailsWeb");
                              var logindetails = JSON.parse(login);

                              if (enteredCouponCode != null) {
                                // setCouponErrorCodeTextBox(null);
                                // setOfferErrorMessageTextBox("");
                                {
                                  GetApiCall.getRequest("GetOfferDetails").then((results) =>
                                    // const objs = JSON.parse(result._bodyText)
                                    results.json().then((obj) => {
                                      if (results.status == 200 || results.status == 201) {
                                        if (obj.data.length != 0) {
                                          var selectedCoupon = obj.data.filter((data) => data.fld_offer_name == enteredCouponCode.toUpperCase() && data.fld_showonwebsite != 'InActive')
                                          if (selectedCoupon.length > 0) {
                                            PostApiCall.postRequest(
                                              {
                                                couponid: selectedCoupon[0]?.fld_offerid,
                                                orderid: localStorage.getItem("OrderData") == null
                                                  ? 0
                                                  : JSON.parse(localStorage.getItem("OrderData")).order_id,
                                                customerid: localStorage.getItem("LoginDetailsWeb") == null
                                                  ? 1
                                                  : logindetails[0]?.CustomerId,
                                                status: "apply",
                                              },
                                              "ApplyOffer"
                                            ).then((result) =>
                                              result.json().then((obj3) => {
                                                if (obj3.message) {
                                                  setCouponErrorCodeTextBox(obj3.message[0]?.error)
                                                  if (obj3.message[0]?.error == "Coupon applied successfully") {
                                                    setCouponAppliedPopup(true)
                                                  }
                                                  getCart();
                                                } else {
                                                  Notiflix.Loading.remove();
                                                  // Notiflix.Notify.failure(
                                                  //   "Soemthing went wrong. Please try again."
                                                  // );
                                                }
                                              })
                                            );
                                          }
                                        } else {
                                          setCouponErrorCodeTextBox(
                                            "Coupon does not exist"
                                          );
                                        }
                                      }
                                    })
                                  );
                                }
                              } else {
                                Notiflix.Notify.failure("Please enter Coupon Code");
                              }
                            }}>
                            Apply Coupon
                          </button>
                        </div>
                        {cartData.length > 0 ? (
                          <>
                            {/*  <Form className="d-flex align-items-center gap-2">
                               <FloatingLabel
                                controlId="floatingCoupon"
                                label="Type coupon code here"
                                className="w-100">
                                <Form.Control
                                  type="text"
                                  placeholder="Type coupon code here"
                                />
                              </FloatingLabel> */}
                            {/* <Form.Group
                        className="form-inline"
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <Form.Control
                          as="textarea"
                          rows={1}
                          placeholder="Type coupon code here"
                        />
                      </Form.Group> */}
                            {/* <Button variant="danger" className="btn-lg">
                                Apply
                              </Button>
                            </Form> */}
                            <Row>
                              <Col lg={12}>
                                {offerList.map((ol) => {
                                  return (
                                    <Card className="shadow-sm border mb-2">
                                      <Card.Body className="coupon-card-body">
                                        <Card.Title className="fw-bold mb-0">{ol.fld_name}</Card.Title>
                                        <p className="small mb-1">
                                          {ol.fld_caption}
                                        </p>
                                        <div className="d-flex justify-content-between align-items-center">
                                          <p className="coupon-code">
                                            {" "}
                                            {ol.fld_code}
                                          </p>
                                          {cartData[0].fld_total_net_amount <
                                            ol.fld_minapplyamnt ? (
                                            <span className="coupon-code-text">{`Add items worth ${currencyCode} ${(
                                              ol.fld_minapplyamnt -
                                              cartData[0].fld_total_net_amount
                                            ).toFixed(
                                              2
                                            )} more to unlock`}</span>
                                          ) :
                                            // cartData[0]?.fld_coupon_discount > 0 &&
                                            // cartData[0]?.fld_coupon_discount != null
                                            cartData[0]?.fld_coupon_id == ol.fld_offerid
                                              ? (
                                                <Button
                                                  onClick={(e) => {
                                                    RemoveCoupon();
                                                  }}
                                                  variant="danger"
                                                  size="sm">
                                                  Remove Coupon
                                                </Button>
                                              ) : (
                                                <Button
                                                  disabled={ol.fld_freeshipping == "Yes" &&
                                                    cartData[0].fld_order_type == "TakeAway"}
                                                  onClick={(e) => {
                                                    var login =
                                                      localStorage.getItem(
                                                        "LoginDetailsWeb"
                                                      );
                                                    var logindetails = login
                                                      ? JSON.parse(login)[0]
                                                      : null;
                                                    PostApiCall.postRequest(
                                                      {
                                                        status: "apply",
                                                        couponid: ol.fld_offerid,
                                                        orderid:
                                                          localStorage.getItem(
                                                            "OrderData"
                                                          ) == null
                                                            ? 0
                                                            : JSON.parse(
                                                              localStorage.getItem(
                                                                "OrderData"
                                                              )
                                                            ).order_id,
                                                        customerid:
                                                          localStorage.getItem(
                                                            "LoginDetailsWeb"
                                                          ) == null
                                                            ? 1
                                                            : logindetails.CustomerId,
                                                      },
                                                      "ApplyOffer"
                                                    ).then((result) =>
                                                      result?.json().then((obj) => {
                                                        if (
                                                          result.status == 200 ||
                                                          result.status == 201
                                                        ) {
                                                          if (obj.message[0]?.error == "Coupon applied successfully" || obj.message.length > 2) {
                                                            Notiflix.Notify.Success("Coupon applied successfully");
                                                            setCouponAppliedPopup(true)
                                                          } else {
                                                            Notiflix.Notify.Failure(obj.message[0]?.error);
                                                          }
                                                          // setAppliedCoupon(ol)
                                                          // Notiflix.Notify.Success(
                                                          //   "Coupon applied."
                                                          // );
                                                        } else {
                                                          Notiflix.Notify.Failure(
                                                            obj.message[0]?.error
                                                          );
                                                        }

                                                        getCart();
                                                      })
                                                    );
                                                  }}
                                                  variant="danger"
                                                  size="sm">
                                                  Apply Coupon
                                                </Button>
                                              )}
                                        </div>
                                      </Card.Body>
                                    </Card>
                                  );
                                })}
                              </Col>
                            </Row>
                          </>
                        ) : (
                          "Please Add Items In Cart"
                        )}
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="7">
                      <Accordion.Header onClick={() => {
                        let key = defaultActiveKey
                        setDefaultActiveKey(key == "7" ? "" : "7")
                      }}>
                        <FaShippingFast className="accordion-icon" />
                        Tip
                      </Accordion.Header>
                      <Accordion.Body>
                        <Form className="d-flex flex-column gap-2">
                          <div className="d-flex">
                            {/* <FloatingLabel
                              controlId="floatingCoupon"
                              label="Enter Tip Amount"
                              className="w-100"
                            >
                              <Form.Control
                                type="text"
                                placeholder="Enter Tip Amount"
                                value={tip}
                                onChange={(e) => { setTip(e.target.value) }}
                              />
                            </FloatingLabel>
                            <Button variant="danger" className="btn-lg ms-3"
                              onClick={() => {
                                PostApiCall.postRequest({
                                  orderid: localStorage.getItem("OrderData") == null ? 0 : JSON.parse(localStorage.getItem("OrderData")).order_id,
                                  tip: tip,
                                }, "AddTip").then((result) =>
                                  result.json().then((obj) => {
                                    // setLoadingSpinnerAddTip(false)
                                    if (result.status == 200 || result.status == 201) {
                                      getCart()
                                      setTip("")
                                    }
                                  })
                                );
                              }}>
                              Add
                            </Button> */}
                          </div>
                          {cartData.length > 0 ? (
                            <>
                              <div className="d-flex mt-3">
                                <Button
                                  variant="danger"
                                  className="btn btn-tips p-1 me-4"
                                  onClick={() => {
                                    addtip(1);
                                    setTipType("");
                                  }}>
                                  {cartData[0]?.currency_code} 1
                                </Button>
                                <Button
                                  variant="danger"
                                  className="btn btn-tips p-1 me-4"
                                  onClick={() => {
                                    addtip(5);
                                    setTipType("");
                                  }}>
                                  {cartData[0]?.currency_code} 5
                                </Button>
                                <Button
                                  variant="danger"
                                  className="btn btn-tips p-1 me-4"
                                  onClick={() => {
                                    addtip(10);
                                    setTipType("");
                                  }}>
                                  {cartData[0]?.currency_code} 10
                                </Button>
                                <Button
                                  variant="danger"
                                  className="btn btn-tips p-1"
                                  onClick={() => {
                                    setTipType("other");
                                  }}>
                                  {tipType === "other" ? (
                                    <Form.Control
                                      type="text"
                                      placeholder="Enter Tip Amount"
                                      value={tip}
                                      onChange={(e) => {
                                        setTip(e.target.value);
                                      }}
                                    />
                                  ) : (
                                    "other"
                                  )}
                                </Button>
                              </div>
                              {tipType === "other" && (
                                <Button
                                  variant="danger"
                                  className="btn-lg mt-2"
                                  onClick={() => {
                                    addtip(tip);
                                  }}>
                                  Add
                                </Button>
                              )}
                            </>
                          ) : (
                            "Please Add Items In Cart"
                          )}
                          {cartData.length > 0 && cartData[0]?.fld_tip !== 0 ? (
                            <div className="d-flex justify-content-between align-items-center">
                              <p>
                                {cartData[0]?.currency_code}{" "}
                                {cartData[0]?.fld_tip} Tip is added
                              </p>
                              <div
                                className="clear-tip-btn"
                                onClick={() => {
                                  addtip(null);
                                }}>
                                Clear Tip
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </Form>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="8">
                      <Accordion.Header onClick={() => {
                        let key = defaultActiveKey
                        setDefaultActiveKey(key == "8" ? "" : "8")
                      }}>
                        {currencyCode} Bill Details
                      </Accordion.Header>
                      <Accordion.Body>
                        <Row>
                          <Col lg={12} className="mb-0">
                            <Card className="bg-white">
                              <Card.Body className="p-0">
                                <h5></h5>
                                <div className="d-flex justify-content-between">
                                  <p className="mb-0">Item Total</p>
                                  <p className="mb-0 fw-bold">
                                    {currencyCode}{" "}
                                    {cartData.length != 0 &&
                                      cartData[0].fld_total_net_amount != null
                                      ? cartData[0].fld_total_net_amount.toFixed(
                                        2
                                      )
                                      : 0.0}
                                  </p>
                                </div>
                                <div className="d-flex justify-content-between">
                                  <p className="mb-0">Delivery Charges</p>
                                  <p className="mb-0 fw-bold">
                                    {currencyCode}{" "}
                                    {cartData.length != 0
                                      ? cartData[0]?.fld_shipping_charge?.toFixed(
                                        2
                                      )
                                      : 0.0}
                                  </p>
                                </div>
                                <hr />
                                {cartData.length != 0 ? (
                                  cartData[0].fld_coupon_code !== null ? (
                                    <div className="d-flex justify-content-between">
                                      <p className="mb-0">
                                        Coupon Discount - (
                                        {cartData.length != 0
                                          ? cartData[0]?.fld_coupon_code
                                          : ""}
                                        ){" "}
                                        <span
                                          onClick={RemoveCoupon}
                                          style={{ cursor: "pointer" }}
                                          className="fw-bold">
                                          remove
                                        </span>
                                        {/* <div className="d-flex justify-content-between align-items-center">
                                          <p className="coupon-code">
                                            {" "}

                                          </p>
                                        </div> */}
                                      </p>
                                      <p className="mb-0 fw-bold">
                                        {currencyCode}{" "}
                                        {cartData.length != 0
                                          ? cartData[0].fld_coupon_discount?.toFixed(
                                            2
                                          )
                                          : 0.0}
                                      </p>
                                    </div>
                                  ) : (
                                    ""
                                  )
                                ) : (
                                  ""
                                )}
                                <div className="d-flex justify-content-between">
                                  <p className="mb-0">Taxes</p>
                                  <p className="mb-0 fw-bold">
                                    {currencyCode}{" "}
                                    {cartData.length != 0 &&
                                      cartData[0].fld_total_gst != null
                                      ? cartData[0].fld_total_gst?.toFixed(2)
                                      : 0.0}
                                  </p>
                                </div>

                                <div className="d-flex justify-content-between">
                                  <p className="mb-0">Tips</p>
                                  <p className="mb-0 fw-bold">
                                    {currencyCode}{" "}
                                    {cartData.length != 0
                                      ? cartData[0].fld_tip?.toFixed(2)
                                      : 0.0}
                                  </p>
                                </div>
                                <hr />
                                <div className="d-flex justify-content-between">
                                  <h5>To Pay</h5>
                                  <h5 className=" fw-bold">
                                    {currencyCode}{" "}
                                    {cartData.length != 0 &&
                                      cartData[0].fld_cart_amount_no_shipping !=
                                      null
                                      ? cartData[0].fld_cart_amount_no_shipping?.toFixed(
                                        2
                                      )
                                      : 0.0}
                                  </h5>
                                </div>
                              </Card.Body>
                            </Card>
                          </Col>
                        </Row>
                      </Accordion.Body>
                    </Accordion.Item>
                  </>
                )}
              </Accordion>
            </Col>
            {/* <Col lg={12}>
              <p
                className="small"
                dangerouslySetInnerHTML={{
                  __html:
                    keyStrings?.filter(
                      (item) =>
                        item.FLD_Key_Name == "QUICK_SEL_CANCELLATION_FEES"
                    )[0] != null
                      ? keyStrings?.filter(
                        (item) =>
                          item.FLD_Key_Name == "QUICK_SEL_CANCELLATION_FEES"
                      )[0].FLD_Key_Value
                      : "<div></div>",
                }}></p>
            </Col> */}
            <Col
              lg={12}
              className="btn-place-order py-lg-2 py-3 bg-white position-sticky zindex-sticky">
              {loginDisabled == true ? (
                <Button
                  variant="danger"
                  className="w-100 text-center mx-auto"
                  size="lg"
                  onClick={(e) => {
                    setShowLoginModal(true);
                  }}>
                  <div>Continue To Order</div>
                </Button>
              ) : (
                <>
                  {addressId == null && deliveryType == "Delivery" ?
                    <Button
                      variant="danger"
                      className="w-100 text-center mx-auto"
                      size="lg"
                      onClick={() => { setDefaultActiveKey("1") }}>
                      Select Delivery Address / Takeout
                    </Button> :
                    <Button
                      variant="danger"
                      className="w-100 text-center mx-auto"
                      size="lg"
                      onClick={() => {
                        var login = localStorage.getItem("LoginDetailsWeb");
                        var logindetails = JSON.parse(login);
                        var Order = localStorage.getItem("OrderData");
                        var Orderdetails = JSON.parse(Order);
                        if (Order != null) {
                          if (
                            logindetails[0]?.CustomerId !== null ||
                            logindetails[0]?.CustomerId !== undefined
                          ) {
                            if (deliveryType !== "TakeAway" && addressId != null) {
                              PostApiCall.postRequest(
                                {
                                  cartid: JSON.parse(
                                    localStorage.getItem("OrderData")
                                  ).order_id,
                                  orderingmethod: deliveryType,
                                  scheduleorder: scheduleOrder,
                                  scheduleorderdate: scheduleOrderDate,
                                  scheduleordertime: scheduleOrderTime,
                                  additionalinfo: additionalInstructions,
                                  deliveryinstruction: deliveryInstructions,
                                  address1: address1,
                                  address2: address2,
                                  customerId:
                                    localStorage.getItem("LoginDetailsWeb") == null
                                      ? null
                                      : JSON.parse(
                                        localStorage.getItem("LoginDetailsWeb")
                                      )[0]?.CustomerId,
                                  // status: "NEW",
                                  addressId: addressId,
                                  coupon: null,
                                },
                                "updateCartAditionalInfo"
                              ).then((result) =>
                                result?.json().then((obj3) => {
                                  if (result.status == 200 || result.status == 201) {
                                    UpdateTrxNumber();
                                    setPaymentModal(true);
                                  } else {
                                    Notiflix.Notify.Failure(
                                      "Please register before placing the order"
                                    );
                                  }
                                })
                              );
                            } else if (deliveryType === "TakeAway") {
                              PostApiCall.postRequest(
                                {
                                  cartid: JSON.parse(
                                    localStorage.getItem("OrderData")
                                  ).order_id,
                                  orderingmethod: deliveryType,
                                  scheduleorder: scheduleOrder,
                                  scheduleorderdate: scheduleOrderDate,
                                  scheduleordertime: scheduleOrderTime,
                                  additionalinfo: additionalInstructions,
                                  deliveryinstruction: deliveryInstructions,
                                  address1: address1,
                                  address2: address2,
                                  customerId:
                                    localStorage.getItem("LoginDetailsWeb") == null
                                      ? null
                                      : JSON.parse(
                                        localStorage.getItem("LoginDetailsWeb")
                                      )[0]?.CustomerId,
                                  // status: "NEW",
                                  addressId: addressId,
                                  coupon: null,
                                },
                                "updateCartAditionalInfo"
                              ).then((result) =>
                                result?.json().then((obj3) => {
                                  if (result.status == 200 || result.status == 201) {
                                    UpdateTrxNumber();
                                    setPaymentModal(true);
                                  } else {
                                    Notiflix.Notify.Failure(
                                      "Please register before placing the order"
                                    );
                                  }
                                })
                              );
                            } else {
                              Notiflix.Notify.Failure(
                                "Please select the address for delivery"
                              );
                            }
                          } else {
                            Notiflix.Notify.Failure(
                              "Unable to process order please login again"
                            );
                          }
                        } else {
                          setShow(false);
                        }
                        // PostApiCall.postRequest(
                        //   {
                        //     orderNumber: 'MOI-101',
                        //     customerName: logindetails[0]?.fld_name,
                        //     amount: cartData[0]?.currency_code + " " + cartData.length != 0 &&
                        //       cartData[0]?.fld_cart_amount_no_shipping != null
                        //       ? cartData[0]?.fld_cart_amount_no_shipping.toFixed(2)
                        //       : 0.0,
                        //     location: 'delhi',
                        //     orderTime: '',
                        //     title: 'Hi, you get a new order',
                        //     body: `${logindetails[0]?.fld_name} made a order,  Order Number: MOI-101`
                        //   },
                        //   "SendAndroidPush"
                        // )
                        //   .then((result) =>
                        //     result?.json().then((obj3) => {
                        //       if (result.status == 200 || result.status == 201) {
                        //         // addAddress();
                        //         updateOrderStatus();
                        //         localStorage.removeItem("OrderData");
                        //         getCart();
                        //         Notiflix.Notify.Success("Your order has been placed successfully");
                        //       } else {
                        //         Notiflix.Notify.Failure("Oops!", "Something went wrong", "OK"
                        //         );
                        //       }
                        //     })
                        //   );

                        // cartData.length == 0
                        //   ? setShow(false)
                        //   :
                      }}>
                      {cartData.length === 0 ? (
                        <div>Add Item(s) To Cart</div>
                      ) : (

                        <div>
                          Place {deliveryType == "TakeAway" ? "Takeout" : deliveryType} Order ({cartData[0]?.currency_code}{" "}
                          {cartData.length != 0 &&
                            cartData[0].fld_cart_amount_no_shipping != null
                            ? cartData[0].fld_cart_amount_no_shipping.toFixed(2)
                            : 0.0}
                          )
                        </div>
                      )}
                    </Button>}
                </>
              )}
            </Col>
          </Row>
        </Offcanvas.Body>
      </Offcanvas>
      <Modal show={showAddressModal} onHide={handleCloseModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Add New Address</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col lg={12} className="address-map mb-4">
              <Map lat={lat} lang={lang} />
            </Col>
            <Col>
              <p
                className="not-delivery-text wrong-address-color"
                style={{ display: noDeliveryZone == true ? "block" : "none" }}>
                Sorry, we don't deliver to this location
              </p>
            </Col>
            <Col lg={12}>
              <Form>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Name"
                  className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder="Example"
                    value={contactName}
                    onChange={(e) => {
                      setContactName(e.target.value);
                    }}
                  />
                </FloatingLabel>
                <div className="d-flex justify-content-between mb-3">
                  <div
                    // label="Flat, House no., Building, Company, Apartment etc."
                    // className="col-11"
                    className={
                      noDeliveryZone == true
                        ? "col-10 col-md-10 col-lg-11 no-delivery-border"
                        : "col-10 pe-3 pe-lg-0 col-md-10 col-lg-11 overflow-hidden"
                    }>
                    <Form.Control
                      type="text"
                      placeholder=""
                      className="col-11 h-100"
                      readOnly
                      value={mapAddress}
                      onChange={(e) => {
                        setMapAddress(e.target.value);
                      }}
                    />
                  </div>
                  <Button
                    variant="primary"
                    type="button"
                    className="btn address-icon btn-danger"
                    onClick={() => {
                      // setNoDeliveryZone(false);
                      // setNoDeliveryZone(true);
                      showMyLocation();
                    }}>
                    <MdLocationOn />
                  </Button>
                </div>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Flat, House no., Building, Company, Apartment etc."
                  className="mb-3 overflow-x-hidden">
                  <Form.Control
                    type="text"
                    placeholder="Flat, House no., Building, Company, Apartment etc."
                    value={confirmAddress}
                    onChange={(e) => {
                      setConfirmAddress(e.target.value);
                    }}
                  />
                </FloatingLabel>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Mobile"
                  className="mb-3">
                  <Form.Control
                    placeholder="Mobile"
                    value={mobileNumber}
                    onChange={(e) => {
                      setMobileNumber(e.target.value);
                    }}
                  />
                </FloatingLabel>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Zipcode"
                  className="mb-3">
                  <Form.Control
                    type="text"
                    placeholder="Zipcode"
                    value={pincode}
                    onChange={(e) => {
                      setPincode(e.target.value);
                    }}
                  />
                </FloatingLabel>
                <div className="d-flex justify-content-between">
                  {noDeliveryZone == false ? (
                    <Button
                      variant="primary"
                      type="button"
                      className="w-100 btn btn-danger btn-lg py-3 fs-6"
                      disabled={noDeliveryZone == true}
                      style={{
                        background: noDeliveryZone == true ? "lightgrey" : "",
                      }}
                      onClick={() => {
                        if (mapAddress != null) {
                          if (pincode != null) {
                            if (confirmAddress != null) {
                              if (mobileNumber != null) {
                                addAddress();
                                // getDistance();
                              } else {
                                Notiflix.Notify.Failure(
                                  "Please enter mobile"
                                );
                              }
                            } else {
                              Notiflix.Notify.Failure(
                                "Please confirm your address"
                              );
                            }
                          } else {
                            Notiflix.Notify.Failure("Please enter zipcode.");
                          }
                        } else {
                          Notiflix.Notify.Failure(
                            "Please detect your location"
                          );
                        }
                      }}>
                      Save Address
                    </Button>
                  ) : (
                    <button
                      type="button"
                      className="saveProceedbtnnodeliveryzone">
                      {" "}
                      Save Address
                    </button>
                  )}
                </div>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleCloseModal}>
            Save Changes
          </Button>
        </Modal.Footer> */}
      </Modal>
      <Modal
        show={showMenu}
        className="menulistquickcategory"
        onHide={handleCloseMenu}>
        <Modal.Header closeButton>
          <Modal.Title>Menu</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6 className="mb-2">Quickly switch between your favorite food</h6>
          <div className="browse-menu-block">
            <h6 className="fw-bold text-danger">Categories</h6>
            <ul className="browse-menu-list">
              {menuListItems.map((MenuListItem) => (
                <li>
                  <div
                    className="item-image"
                    value={MenuListItem.fld_name}
                    onClick={(e) => {
                      setScrollTodiv(MenuListItem.fld_category_id);
                      setActiveTab(MenuListItem.fld_category_id);
                      windowScrollY();
                      setSearchText(MenuListItem.fld_name);
                      handleCloseMenu();
                    }}>
                    <img
                      onClick={(e) => {
                        const removeFav = allcuisines;
                        setcuisines(
                          removeFav.filter((product) =>
                            product.Category_name.includes(
                              e.target.getAttribute("alt")
                            )
                          )
                        );
                        setSearchText(e.target.getAttribute("alt"));
                      }}
                      className="img-fluid"
                      src={MenuListItem.fld_image}
                      alt={MenuListItem.fld_name}
                    />
                    <p className="mb-0">{MenuListItem.fld_name}</p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={paymentModal == true ? true : false}
        onHide={handlepaymentModalClose}
        backdrop="static"
        keyboard={false}
        size="md"
        className="modalposition2 modal-sec no-header"
        centered>
        <Modal.Header
          className="modal-sec-head bg-white flex-column payment-modal-header position-relative"
          style={{ background: "#f0f0f2", justifyContent: "center" }}
          closeButton>
          <div className="modal-sec-img">
            {" "}
            <img src={clientLogo} className="img-fluid w-75" alt="Logo"></img>
          </div>
          {/* <div style={{ fontWeight: "700" }}> Masala Of India</div> */}
        </Modal.Header>
        <Modal.Body
          className="m-0 p-4 modal-sec-body"
          style={{ borderRadius: "0 0 5px 5px" }}>
          <div className="payment-modal">
            <div className="row px-2 mb-3">
              <div className="col-12 px-0 position-relative">
                <FaMailchimp className="modal-sec-icon-2"></FaMailchimp>
                <input
                  className="form-control"
                  placeholder="Email"
                  value={paymentEmail}
                  onChange={(e) => {
                    setpaymentEmail(e.target.value);
                  }}></input>
              </div>
            </div>
            <div className="row px-2 mb-3">
              <div className="col-12 px-0 position-relative">
                <VscCreditCard className="modal-sec-icon"></VscCreditCard>
                <input
                  className="form-control"
                  placeholder="Card Number"
                  value={paymentCardNumber}
                  onChange={onChangeCardNumber}></input>
              </div>
            </div>
            <div className="row px-2 mb-3">
              <div className="col-6 px-0 position-relative">
                <BiSolidCalendar className="modal-sec-icon-2"></BiSolidCalendar>
                <input
                  className="form-control"
                  placeholder="MM/YY"
                  onChange={onChangeExp}
                  value={expriy_format(paymentExpiry)}></input>
              </div>
              <div className="col-6 pe-0 position-relative">
                <BiLockAlt className="modal-sec-icon ms-2"></BiLockAlt>
                <input
                  className="form-control"
                  placeholder="CVV"
                  value={paymentCVC}
                  onChange={(e) => {
                    if (
                      numRegex.test(e.target.value) &&
                      e.target.value.length <= 4
                    ) {
                      setPaymentCVC(e.target.value);
                    }
                  }}></input>
              </div>
            </div>

            <button
              className="w-100 text-center mx-auto btn btn-danger btn-lg"
              onClick={() => {
                // updatePaymentStatus("5345435");
                onPaymentKeyBank();
                // CustomerOrderMailer();
              }}>
              Pay Now {cartData[0]?.currency_code} {totalAmount}
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        // show={paymentModal == true ? true : false}
        show={paymentSuccesModal}
        onHide={handleClosepaymentSuccesModal}
        // backdrop="static"
        // keyboard={false}
        size="lg"
        className="modalposition2 modal-sec no-header thankyou-modal"
        centered>
        <Modal.Header closeButton className="border-0 pb-0"></Modal.Header>
        <Modal.Body className="pt-0">
          <div className="w-100 text-center">
            <img src={thankyouImg} className="img-fluid" />
            <h3>Order Confirmed!</h3>
          </div>
          <p>
            Your payment was successful, and your order is being processed.
            We're cooking up your delicious meal right now!
            <GiChickenOven className="mx-2" />{" "}
          </p>
          <p>We'll notify you as soon as your order is on its way.</p>
          <p>
            {" "}
            Thank you for choosing <b>Masala of India</b>
          </p>
          <div className="text-center w-100 mt-3">
            <button className="btn btn-danger btn-md">Continue Ordering</button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default QuickSelection;
