import "animate.css";
import { DatePicker } from 'antd';
import dayjs from "dayjs";
import { useFormik } from "formik";
import moment from "moment/moment";
import Notiflix from "notiflix";
import * as Yup from 'yup';
import PostApiCall from "../../../helper/PostAPI";
import "../assets/css/style.css";
import LeftIcon from "../assets/images/catering/booking-left.png";
import "./Catering.css";

const Catering = () => {
  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      email: "",
      people: "",
      date: "",
      occasion: "",
      servings: "",
      budget: "",
      comments: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("This field is required*"),
      phone: Yup.string().matches(/^\d+$/, "Phone number is invalid").required("This field is required*"),
      email: Yup.string().email("Email is Invalid").required("This field is required*"),
      people: Yup.string().matches(/^\d+$/, "Number of people is invalid").required("This field is required*"),
      date: Yup.date().required("This field is required*"),
      occasion: Yup.string().required("This field is required*"),
      // servings: Yup.string().matches(/^\d+$/, "Number of servings is invalid").required("This field is required*"),
      budget: Yup.string().matches(/^\d+$/, "Budget is invalid").required("This field is required*"),
      // comments: Yup.string().required("This field is required"),
    }),
    onSubmit: values => {
      // console.log(values)
      const { name, phone, email, people, date, occasion, servings, budget, comments } = values
      sendMail(name, phone, email, people, date, occasion, servings, budget, comments)
      formik.resetForm();
    }
  })

  // send mail to the admin
  function sendMail(name, phone, email, people, date, occasion, budget, comments) {
    // const formattedDate = moment(date).format('YYYY-MM-DD HH:mm:ss');

    PostApiCall.postRequest(
      {
        id: null,
        contactperson: name,
        contactnumber: phone,
        email: email,
        peoplecount: people,
        pickupdate: date,
        occasion: occasion,
        // servings: servings,
        personbudget: budget,
        message: comments,

      },
      "updateenquiries"
    ).then((result) =>
      result?.json().then((obj) => {
        if (result.status == 200 || result.status == 201) {
          // console.log("Mail sent")
          Notiflix.Notify.Success("Mail sent")
        } else {
          // console.log("Failed")
          Notiflix.Notify.Failure("Failed")
        }
      })
    );
  }

  return (
    <>
      <section className="book-table-section mt-5" id="catering">
        <div className="container-md container-fluid px-0 px-3">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="content">
                <div className="section-header mb-1">
                  <h2>Tailored to Your Taste</h2>
                  <h3>Transforming events into your flavourful feasts</h3>
                </div>
                {/* <div className="d-lg-flex"> */}
                <p className="masala-common-content me-lg-4">
                  Discover the Excellence of Off-Campus Catering with Masala of
                  India - Seattle's Premier Choice! Our team comprises talented,
                  highly skilled, and impeccably trained staff members,
                  dedicated to ensuring your satisfaction. Masala of India is
                  your go-to catering company, whether it's an intimate
                  gathering, a corporate event, a wedding, or any special
                  occasion. With two decades of catering expertise, we've honed
                  our craft to perfection, understanding the nuances of flavour
                  profiles to offer customisable spice levels that cater to
                  diverse palates. Contact us at{" "}
                  <a href="tel:+858999-4604" className="text-dark fs-5 fw-bold">
                    {/* <i>+1(206) 369-6165</i> */}
                    <i>+1(858) 999-4604</i>
                  </a>{" "}
                  to elevate your event with our unparalleled culinary
                  experience.
                </p>
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-lg-12">
              <div class="book-table-box home-catering-form">
                <div class="title-area mt-0 mb-5">
                  <img src={LeftIcon} alt="masala-of-india" />
                  <h2 class="table-title">Catering</h2>
                  <img src={LeftIcon} alt="masala-of-india" />
                </div>

                {/*  Catering form start */}
                <div class="book-form">
                  <form action="#" onSubmit={formik.handleSubmit}>
                    <div class="row">
                      <div class="col-12 col-md-4 mb-4">
                        <input
                          class="form-control form-control-lg"
                          type="text"
                          placeholder="Name *"
                          name="name"
                          value={formik.values.name}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          aria-label=".form-control-lg example"
                        />
                        {formik.touched.name && formik.errors.name ? <div style={{ color: "red" }}>{formik.errors.name}*</div> : null}
                      </div>
                      <div class="col-12 col-md-4 mb-4">
                        <input
                          class="form-control form-control-lg"
                          type="text"
                          placeholder="Phone Number *"
                          name="phone"
                          value={formik.values.phone}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          aria-label=".form-control-lg example"
                        />
                        {formik.touched.phone && formik.errors.phone ? <div style={{ color: "red" }}>{formik.errors.phone}</div> : null}
                      </div>

                      <div class="col-12 col-md-4 mb-4">
                        <input
                          class="form-control form-control-lg"
                          type="email"
                          placeholder="Email Address *"
                          name="email"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          aria-label=".form-control-lg example"
                        />
                        {formik.touched.email && formik.errors.email ? <div style={{ color: "red" }}>{formik.errors.email}</div> : null}

                      </div>

                      <div class="col-12 col-md-6 mb-4">
                        <input
                          class="form-control form-control-lg"
                          type="text"
                          placeholder="Number of People *"
                          name="people"
                          value={formik.values.people}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          aria-label=".form-control-lg example"
                        />
                        {formik.touched.people && formik.errors.people ? <div style={{ color: "red" }}>{formik.errors.people}</div> : null}
                      </div>

                      {/* <div class="col-12 col-md-6">
                        <label className="mb-2 catering-label">
                          Desired Date and Pickup Time *
                        </label>
                        <input
                          class="form-control form-control-lg catering-label"
                          type="date"
                          aria-label=".form-control-lg example"
                          value={formik.values.date}
                          name="date"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          min={new Date().toISOString().split('T')[0]}
                        />
                        {formik.touched.date && formik.errors.date ? <div style={{ color: "red" }}>{formik.errors.date}</div> : null}
                      </div> */}

                      <div className="col-12 col-md-6 mb-4">
                        {/* <label className="mb-2 catering-label">
                          Desired Date and Pickup Time *
                        </label> */}
                        <DatePicker
                          placeholder="Desired Date and Time *"
                          showTime={{ format: 'hh:mm A' }}
                          format="MM-DD-YYYY HH:mm:ss"
                          className="form-control form-control-lg catering-label"
                          value={formik.values.date
                            ? dayjs(formik.values.date)
                            : null}
                          onChange={(date, dateString) => {
                            formik.setFieldValue('date', dateString)
                          }}
                          // onBlur={formik.handleBlur('date')}
                          disabledDate={current => current && current < moment().startOf('day')} // To disable past dates
                        />
                        {formik.touched.date && formik.errors.date ? <div style={{ color: "red" }}>{formik.errors.date}</div> : null}
                      </div>


                      <div class="col-lg-6 mb-4 col-12">
                        <select
                          placeholder="Select Occasion *"
                          class="form-select form-control form-control-lg"
                          id="inputGroupSelect01"
                          value={formik.values.occasion}
                          name="occasion"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        >
                          <option selected> Select Occasion *</option>
                          <option value="Birthday">Birthday</option>
                          <option value="Graduation">Graduation</option>
                          <option value="Holiday Party">
                            Holiday Party
                          </option>
                          <option value="Baby Shower">Baby Shower</option>
                          <option value="Bridal Shower">
                            Bridal Shower
                          </option>
                          <option value="Rehearsal Dinner">
                            Rehearsal Dinner
                          </option>
                          <option value="Retirement">Retirement</option>
                          <option value="Bachelor/Bachelorette">
                            Bachelor/Bachelorette
                          </option>
                        </select>
                        {formik.touched.occasion && formik.errors.occasion ? <div style={{ color: "red" }}>{formik.errors.occasion}</div> : null}

                      </div>


                      <div className="col-12 col-md-6">
                        <div className="row">
                          {/* <div class="col-12 mb-4">
                                <input
                                  class="form-control form-control-lg"
                                  type="text"
                                  placeholder="Number of Servings *"
                                  name="servings"
                                  aria-label=".form-control-lg example"
                                  value={formik.values.servings}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                />
                                {formik.touched.servings && formik.errors.servings ? <div style={{ color: "red" }}>{formik.errors.servings}</div> : null}
                              </div> */}

                          <div class="col-12 mb-4">
                            <input
                              class="form-control form-control-lg"
                              type="text"
                              placeholder="Budget per person *"
                              name="budget"
                              aria-label=".form-control-lg example"
                              value={formik.values.budget}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            {formik.touched.budget && formik.errors.budget ? <div style={{ color: "red" }}>{formik.errors.budget}</div> : null}
                          </div>
                          {/* <div class="col-12">
                                <input
                                  class="form-control form-control-lg"
                                  type="text"
                                  placeholder="Amount of People *"
                                  name=""
                                  aria-label=".form-control-lg example"
                                />
                              </div> */}
                        </div>
                      </div>
                      <div className="col-12 mb-4">
                        <textarea
                          class="form-control form-control-lg catering-textarea"
                          id="validationTextarea"
                          placeholder="Additional Comments"
                          name="comments"
                          value={formik.values.comments}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {/* {formik.touched.comments && formik.errors.comments ? <div style={{ color: "red" }}>{formik.errors.comments}</div> : null} */}
                      </div>


                      <div class="col-lg-12 text-center">
                        <button type="submit" class="custom-button">
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Catering;
